import React from 'react'

export const PrivacyPolicy = () => (
  <p className='flex justify-center h-screen pt-40 text-white bg-gray-800'>
    Privacy protection is important to us. The below describes how we process
    personal data through your participation in our STL SWING DANCE events
    (“Events”). STL SWING LLC, located at 14248 Manchester Rd Ste F400,
    Manchester MO 63011 ("STL SWING") is the data controller for these Events.
    STL SWING is responsible for, and controls, the processing of your personal
    data. What personal data do we collect? In the context of our Events, we
    collect personal data about you, which can include name, e-mail address,
    phone number, address, birth date, place of birth, gender, information on
    medical conditions and medications, emergency contact information, relay
    information, and other information you provide during registration. In the
    course of preparing the Event and during the Event, we might collect other
    information, such as follow/lead information as well as your performance in
    the Event. How do we collect your personal data? Typically, we receive your
    personal data from different sources. Most of the personal data we receive
    comes to us voluntarily from you in the course of registering for an Event.
    You are free to choose which information you want to provide to us or
    whether you want to provide us with personal data at all. However, some
    information, such as information requested in the registration procedure and
    information collected during the Event, may be necessary for the performance
    of our contractual obligations in the context of your Event participation.
    Without providing this personal data, you will not be able to enter into a
    contract with us by registering for our Events. During the Event we will
    receive personal data on your performance and on incidences in which you are
    involved by our service providers. Why and on which legal basis do we
    collect and use your personal data? The reasons for using your personal data
    may differ depending on the purpose of the collection. We use your personal
    data to conduct our Events and make your participation in our Events
    possible. Some of our Events could be part of bigger Event series and could
    therefore interlinked. By participating in one Event, dancers maybe be able
    to qualify for other Events. For this reason, personal data collected in one
    Event will potentially be used for the qualification of other Events and in
    the context of our overall Event series. The usage of your personal data may
    be necessary for our own business interests. For example, we may use some of
    your personal data to evaluate and review our Events, the demographics of
    our dance students, our overall business performance (such as Event
    participation, cancellations, Event related incidents), and to improve and
    individualize your experience and enjoyment of our Events. If necessary, we
    may also use your personal data to pursue or defend ourselves against legal
    claims. In some cases, we may ask you to grant us separate consent to use
    your personal data. Where we ask you for your consent, you are free to deny
    your consent and the denial will have no negative consequences for you. You
    are also free to withdraw your consent at any time with effect for the
    future. If you have granted us consent to use your personal data, we will
    use it only for the purposes specified in the consent form. Note that we are
    obligated to retain certain personal data because of legal requirements, for
    example, tax or commercial laws, or we may be required by law enforcement to
    provide personal data on request. With whom do we share your personal data?
    We share your personal data with third-party vendors and other service
    providers that perform services for us, which may include medical support,
    Event surveillance, time keeping, services related to the registration and
    organization of our Events, payment processing, data enhancement services,
    or fraud prevention. If applicable, such service providers will, by
    appropriate data processing agreements, be bound to process personal data
    only on our behalf and under our instructions. If you participate in our
    Events and have given us consent to do so, we will disclose your name,
    e-mail address, and phone number to the Event photographer, who may contact
    you with photos from the attended Event. Personal data may also be disclosed
    to third parties as required by law or subpoena or if we reasonably believe
    that such action is necessary to (a) comply with the law and the reasonable
    requests of law enforcement; (b) to enforce our legal claims or to protect
    the security or integrity of our Services; and/or (c) to exercise or protect
    the rights, property, or personal safety of STL
  </p>
)
