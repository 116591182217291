import { RouteNames } from 'app/Routes'
import { useRouter } from 'app/shared/hooks'
import React from 'react'

type Props = {
  route: RouteNames
  dropDownLink?: boolean
  onClose?: () => void
} & AnchorElement

export const HeaderLink = ({ children, route, ...props }: C<Props>) => {
  const { navigateTo, currentRoute } = useRouter()

  const dropDownLinkStyles =
    'text-gray-500 hover:bg-gray-300 hover:border-white  hover:text-gray-900'

  const onClick = () => {
    if (props.onClose) props.onClose()
    navigateTo(route)
  }

  return (
    <a
      {...props}
      onClick={onClick}
      className={`px-4 py-2 font-medium items-center jutify-center flex text-white cursor-pointer transition-all duration-500 border-b-2 border-transparent hover:border-white ${
        props.className
      } ${currentRoute === route ? 'border-white' : ''} ${
        props.dropDownLink ? dropDownLinkStyles : ''
      }`}>
      {children}
    </a>
  )
}
