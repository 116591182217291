import React from 'react'

type Props = {
  bgColor?: 'light' | 'dark'
  textSize?: 'small' | 'medium' | 'large'
}

export const Panel = ({ bgColor, textSize, className, ...props }: C<Props>) => {
  const getBgColor = () => {
    if (bgColor === 'dark') return 'bg-gray-800'
    if (bgColor === 'light') return 'bg-white'
    return 'bg-white'
  }
  const getTextColor = () => {
    if (bgColor === 'dark') return 'text-white'
    if (bgColor === 'light') return 'text-gray-800'
    return 'bg-white'
  }
  const getTextSize = () => {
    if (textSize === 'small') return ''
    if (textSize === 'medium') return ''
    if (textSize === 'large') return ''
    return ''
  }

  return (
    <div
      className={`flex justify-center px-2 py-8 sm:py-16 sm:px-8 ${
        getBgColor() + ' ' + getTextColor() + ' ' + className
      }`}>
      <div className={'text-2xl font-extrabold sm:px-1/4 ' + getTextSize()}>
        {props.children}
      </div>
    </div>
  )
}
