import React, { useEffect, useState } from 'react'
import { FirestoreImage } from './FirestoreImage'
import {
  FormModal,
  Modal,
  Select,
  Input,
  Textarea,
  Option,
  Loading,
} from 'app/shared'
import { useForm } from 'react-hook-form'
import { Asset } from '../Assets.types'
import { FilePond, registerPlugin } from 'react-filepond'
import { ActualFileObject } from 'filepond'

// Filepond Plugins
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileRename from 'filepond-plugin-file-rename'
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginGetFile from 'filepond-plugin-get-file'

import { filter, head, isEmpty, isNil } from 'ramda'
import { useFirestore } from 'app/firebase'
import { toast } from 'react-toastify'
import { uploadFile, getImageSrc } from '../helpers/asset.helpers'

// Register Filepond Plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileRename,
  FilePondPluginMediaPreview,
  FilePondPluginImageResize,
  FilePondPluginGetFile,
)

type Props = {
  asset?: Asset
  isOpen: boolean
  onClose: () => void
}

export const AssetForm = ({ isOpen, asset, onClose }: C<Props>) => {
  const form = useForm()
  const [assetType, setAssetType] = useState<Asset['type']>('image')
  const [error, setError] = useState<string>()
  const [files, setFiles] = useState<ActualFileObject[]>([])
  const [downloadLink, setDownloadLink] = useState<string>()
  const [uploading, setUploading] = useState(false)
  const { createWithId: createAssetWithId, update: updateAsset } =
    useFirestore('assets')

  useEffect(() => {
    if (!asset) return

    getImageSrc(asset.fullPath).then(setDownloadLink)
  }, [asset])

  type FormData = {
    name: string
    type: Asset['type']
    alt?: string
  }

  const onSubmit = async (formData: FormData) => {
    if (isEmpty(files)) return
    if (assetType === 'album') return

    // console.log(new File(head(files) as string))

    setUploading(true)

    if (asset) {
      await updateAsset(asset.id, {
        ...asset,
        ...formData,
      })
      setUploading(false)
      return
    }

    await uploadFile(head(files) as File)
      .then(({ ref, metadata }) => {
        toast.success('Image Uploaded')
        createAssetWithId({
          // ...metadata,
          name: formData.name,
          type: formData.type,
          alt: formData.alt,
          bucket: ref.bucket,
          fileName: ref.name,
          fullPath: ref.fullPath,
          size: metadata.size,
          contentType: metadata.contentType,
        })

        console.log('ref', ref)
        console.log('metadata', metadata)
      })
      .catch((err) => {
        console.error(err)
        toast.error(err)
      })
    setUploading(false)
  }

  return (
    <FormModal
      title='Add Asset'
      type='right-panel'
      className='w-screen sm:w-1/2'
      isOpen={isOpen}
      form={form}
      isValid={files.length > 0}
      onSubmit={onSubmit}
      onClose={onClose}>
      {uploading ? (
        <div className='w-full h-screen p-8'>
          <span className='flex justify-center align-middle'>
            <Loading className='text-center flex justify-center h-24 w-24' />
          </span>
          <span className='font-semibold text-lg text-center align-middle flex justify-center'>
            currently uploading file, please wait. Do not close the browser or
            this panel. For large files, this could take a moment.
          </span>
        </div>
      ) : (
        <div className='p-4 space-y-2'>
          <Input
            label='Asset Name'
            form={form}
            name='name'
            required
            defaultValue={asset?.name}
          />
          <Select
            form={form}
            name='type'
            label='Asset Type'
            onSelect={setAssetType}
            required
            defaultValues={asset ? [asset?.type] : []}>
            <Option label='Photo' value='photo' />
            <Option label='Video' value='video' />
            <Option label='Album' value='album' />
            <Option label='file' value='file' />
          </Select>

          <Textarea label='Alt Text' form={form} name='alt' />

          {!asset ? (
            <FilePond
              files={files}
              onupdatefiles={(filePondFiles) => {
                // @ts-ignore
                setFiles(filePondFiles.map(({ file }) => file))
                console.log(filePondFiles.map((file) => file.source))
              }}
              allowMultiple={assetType === 'album'}
              allowFileRename
              allowReplace
              allowReorder
              // onremovefile={file => }
              allowImageResize
              allowRevert
              onaddfileprogress={console.log}
              fileRenameFunction={(fileData) =>
                new Date().getTime() + '__' + fileData.name.replace(' ', '_')
              }
              maxFiles={3}
              name='files'
              labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
              // @ts-ignore
              credits={false}
            />
          ) : (
            <p className='bg-red-100'>
              You Can Not Change An Assets Link Once It's Uploaded
            </p>
          )}

          {asset && downloadLink && asset.type === 'image' && (
            <div>
              <a
                className='focus:shadow-outline hover:bg-indigo-900 px-4 py-2 mb-8 font-medium text-white transition duration-300 bg-indigo-700 rounded'
                target='_blank'
                href={downloadLink}>
                View Media
              </a>
              <FirestoreImage fullPath={downloadLink} type='image' />
            </div>
          )}
          {asset && downloadLink && asset.type === 'video' && (
            <div>
              <a
                className='focus:shadow-outline hover:bg-indigo-900 px-4 py-2 mb-8 font-medium text-white transition duration-300 bg-indigo-700 rounded'
                target='_blank'
                href={downloadLink}>
                View Media
              </a>
              <video
                autoPlay={false}
                preload='auto'
                controls
                playsInline
                className='object-cover w-full h-full'>
                {/* <source src={downloadLink} type={`video/mov`} /> */}
                <source src={downloadLink} />
              </video>
            </div>
          )}

          {error}
          {!asset && files.length === 0 && (
            <p className='text-red-500'>
              Must Have At Least One Image Selected
            </p>
          )}
        </div>
      )}
    </FormModal>
  )
}
