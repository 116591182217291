import { PageHeading } from 'app/layout/PageHeading'
import { Panel } from 'app/shared/components/Layout/Panel/Panel'
import React from 'react'
import { AvailabilityForm } from '../components/PrivateLessons/AvailabilityForm'

export const PrivateLessonsPage = () => {
  return (
    <section className='relative z-0 bg-gray-200' tabIndex={0}>
      <PageHeading title='Coming Soon!'></PageHeading>

      <Panel bgColor='light' className='text-white'>
        We are still setting up the software that will allow online sign ups for
        private lessons. Check back in a few weeks!
      </Panel>
    </section>
  )
}

export default PrivateLessonsPage
