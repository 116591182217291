import React, { useEffect, useState } from 'react'
import { useFirestore } from 'app/firebase'
import { PageHeading } from 'app/layout/PageHeading'
import { EventCard } from '../components/Events/EventCard'
import { propEq } from 'ramda'
import { useRouter } from 'app/shared/hooks'
import { useAnalytics } from 'app/shared/hooks/useAnalytics'
import { addHours, compareAsc, isBefore } from 'date-fns'
import { Loading } from 'app/shared/components/Loading/Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

type Props = {}

export const EventsPage = (props: Props) => {
  const [redirecting, setRedirecting] = useState(false)

  return (
    <section className='relative z-0 w-screen'>
      <PageHeading title='Social Dances' image='images/group-classes.jpg' />

      <p className='text-yellow-800 text-center max-w-fit mx-auto bg-yellow-200 rounded-lg p-4 m-4'>
        We are in the process of changing registration platforms
      </p>
      <div className='w-full text-center'>
        <a
          className='relative px-4 py-2 text-sm  transition-all duration-150 ease-in-out group font-medium rounded bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:bg-gray-300 disabled:text-gray-600 disabled:hover:text-gray-600 space-x-2'
          href={'https://signup.software/store/1/dances'}
          onClick={() => setRedirecting(true)}>
          {redirecting && (
            <FontAwesomeIcon
              icon={faSpinner}
              className={`text-gray-500 h-4 w-4 group-hover:text-blue-700 ${
                redirecting ? 'animate-spin' : ''
              }`}
            />
          )}
          View our dances
        </a>
      </div>
    </section>
  )

  // const { list: events } = useFirestore('events')

  // const { pageViewEvent } = useAnalytics()
  // const { history } = useRouter()

  // useEffect(() => {
  //   const unlisten = history.listen(() => {
  //     window.scrollTo(0, 0)
  //   })
  //   return () => {
  //     unlisten()
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // useEffect(() => {
  //   pageViewEvent('Events Page')
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // return (
  //   <section className='relative z-0 w-screen' tabIndex={0}>
  //     <PageHeading title='Social Dances & Events' />

  //     {!events && (
  //       <div className='h-96 flex justify-center w-screen'>
  //         <Loading />
  //       </div>
  //     )}

  //     {/* {allEvents
  //       .filter(propEq('type', 'Monthly'))
  //       .filter(
  //         (event) => event && isAfter(new Date(event.dateTime), new Date()),
  //       ).length > 0 && <Panel bgColor='dark'>Unique Events</Panel>} */}

  //     {/* <div className='md:grid md:grid-cols-2 sm:max-w-6xl sm:px-10 lg:px-12 px-2 mx-auto'></div> */}

  //     <div className='md:grid md:grid-cols-2 sm:max-w-6xl sm:px-10 lg:px-12 px-2 mx-auto'>
  //       {events
  //         ?.filter((event) => !event.draft)
  //         ?.filter(
  //           ({ type }) =>
  //             type === 'Monthly' ||
  //             type === 'Chelsea Reed' ||
  //             type === 'Harmonizers Dance',
  //         )
  //         .filter(({ dateTime }) =>
  //           isBefore(new Date(), addHours(new Date(dateTime), 2)),
  //         )
  //         .sort((a, b) =>
  //           compareAsc(new Date(a.dateTime), new Date(b.dateTime)),
  //         )
  //         .map((event) => (
  //           <EventCard event={event} />
  //         ))}

  //       {events
  //         ?.filter(propEq('type', 'Weekly'))
  //         .filter(({ dateTime }) =>
  //           isBefore(new Date(), addHours(new Date(dateTime), 2)),
  //         )
  //         .sort((a, b) =>
  //           compareAsc(new Date(a.dateTime), new Date(b.dateTime)),
  //         )
  //         .map((event) => (
  //           <EventCard event={event} />
  //         ))}
  //     </div>
  //   </section>
  // )
}

export default EventsPage
