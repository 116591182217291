import React, { useState } from 'react'
import { Loading } from '../Loading/Loading'

export type Props = {
  src?: string
  alt: string
  height?: string
  width?: string
  fit: 'contain' | 'cover'
  overlay?: boolean | `${'light' | 'dark'} ${'red' | 'base'}`
  firestoreSrc?: string
}

/**
 * @param src name of image(with suffix)
 * @param alt image alt text
 * @param fit object-fit css property. contain or cover;
 */
export const Video = ({ src, alt, fit, ...props }: C<Props>) => {
  const sharedClasses = `absolute top-0 left-0 w-full h-full`
  const [loading, setLoading] = useState(true)
  const [brokenLink, setBrokenLink] = useState(false)

  const getOverlay = () => {
    if (!props.overlay) return ''
    const baseClasses = 'rounded-lg bg-blend-overlay'
    const baseOverlay = `${baseClasses} bg-gray-800 bg-opacity-75`
    const lightRedOverlay = `${baseClasses} bg-orange-800 bg-opacity-50`
    const darkRedOverlay = `${baseClasses} bg-orange-800 bg-opacity-75`
    const darkOverlay = `${baseClasses} bg-gray-800 bg-opacity-75`
    const lightOverlay = `${baseClasses} bg-gray-800 bg-opacity-50`

    if (typeof props.overlay === 'boolean') return baseOverlay
    if (props.overlay === 'light red') return lightRedOverlay
    if (props.overlay === 'dark red') return darkRedOverlay
    if (props.overlay === 'dark base') return darkOverlay
    if (props.overlay === 'light base') return lightOverlay
    return ''
  }

  const getClasses = () => {
    const overlayClasses = getOverlay()
  }

  const getLink = () => {
    const noLink = !src && !props.firestoreSrc
    if (src) return require(`../../../../assets/${src}`)
    if (props.firestoreSrc) return props.firestoreSrc
    return ''
  }

  return (
    <section className={`relative overflow-hidden ${props.className}`}>
      <video
        autoPlay
        preload='auto'
        height={props.height ? props.height : '100%'}
        width={props.width ? props.width : '100%'}
        muted
        loop
        onError={() => setBrokenLink(true)}
        onLoadedData={() => {
          setLoading(false)
          setBrokenLink(false)
        }}
        poster={'/src/assets/images/lhsl-home-cover.jpg'}
        playsInline
        src={
          getLink()
          // props.firestoreSrc
          //   ? props.firestoreSrc
          //   : require(`../../../../assets/${
          //       src ? src : 'videos/skye-frida-swingouts.mp4'
          //     }`)
        }
        className={`object-${fit} z-10 ${sharedClasses}`}
      />

      <div
        className={`z-20 ${sharedClasses} ${getOverlay()}
       `}>
        {loading && <Loading className='w-16 h-16' />}
        {/* This Content will overlayed on top of the image */}
        {props.children}
      </div>
    </section>
  )
}
