import React from 'react'
import { HomeIcon } from './HomeIcon'
import { SearchIcon } from './SearchIcon'
import { PeopleIcon } from './PeopleIcon'
import { FolderIcon } from './FolderIcon'
import { CalendarIcon } from './CalendarIcon'
import { DocumentIcon } from './DocumentIcon'
import { ReportsIcon } from './ReportsIcon'
import { ArrowDownIcon } from './ArrowDownIcon'

export type IconNames =
  | 'home'
  | 'people'
  | 'folder'
  | 'calendar'
  | 'reports'
  | 'document'
  | 'bell'
  | 'search'
  | 'arrow-down'
  | 'arrow-back'
  | 'Checkbox'
  | 'Eye'
  | 'People'
  | 'pointed-arrow-up'
  | 'search'
  | 'warning'

type Props = {
  iconName: IconNames
}

export const Icon = ({ iconName, className }: C<Props>) => {
  return (
    <>
      {iconName === 'home' && <HomeIcon className={className} />}
      {iconName === 'people' && <PeopleIcon />}
      {iconName === 'folder' && <FolderIcon />}
      {iconName === 'calendar' && <CalendarIcon />}
      {iconName === 'reports' && <ReportsIcon />}
      {iconName === 'document' && <DocumentIcon />}
      {iconName === 'search' && <SearchIcon />}
      {iconName === 'arrow-down' && <ArrowDownIcon />}
    </>
  )
}
