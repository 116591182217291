import { toast } from 'react-toastify'
import {
  getStorage,
  getDownloadURL,
  listAll,
  ref,
  uploadBytes,
} from 'firebase/storage'
import { firebaseApp } from 'environments'

/** Upload as File to Firebase Storage */
export const uploadFile = async (file: File /** options: Options */) => {
  const Storage = getStorage(firebaseApp)
  const storageRef = ref(Storage, file.name)

  return uploadBytes(storageRef, file)
}

/** Gets a url that can be displayed inside of an <img /> src attribute from the fullPath of a firebase storage ref */
export const getImageSrc = async (path: string) =>
  await getDownloadURL(ref(getStorage(firebaseApp), path))

export const getImageSrcList = async (paths: string[]) =>
  await Promise.all(paths.map(getImageSrc))

export const getPhotoAlbumPaths = async (name: string) =>
  await Promise.all(
    await listAll(ref(getStorage(firebaseApp), 'albums/' + name)).then((ref) =>
      ref.items.map((i) => i.fullPath),
    ),
  )
export const getPhotoAlbum = async (name: string) =>
  await Promise.all(
    await listAll(ref(getStorage(firebaseApp), 'albums/' + name)).then((ref) =>
      ref.items.map(getDownloadURL),
    ),
  )

export const getSavedFiles = async () =>
  await listAll(ref(getStorage(firebaseApp)))
    .then((ref) =>
      ref.items.map((ref) => ({
        fullPath: ref.fullPath,
        name: ref.name,
        src: getDownloadURL(ref),
      })),
    )
    .catch((err) => toast(err, { type: 'error' }))
