import { useKeybind } from 'app/shared/hooks'
import React, { useLayoutEffect } from 'react'
import { animated, useTransition } from 'react-spring'
import { useClickOutside } from '../Modal/useClickOutside'

type Props = {
  isVisible: boolean
  onClose: () => void
  menuWidth?: string | number
  style?: Omit<React.CSSProperties, 'scale'>
}

export const Menu = ({ isVisible, onClose, ...props }: C<Props>) => {
  const menuRef = useClickOutside<HTMLDivElement>(isVisible, onClose)

  useLayoutEffect(() => {
    if (!menuRef.current) return
    // setPosition(menuRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuRef.current])

  useKeybind(['Escape'], onClose, menuRef)

  const transitions = useTransition(isVisible, {
    config: {
      friction: 32,
      mass: 1,
      tension: 1000,
    },
    enter: {
      transform: 'translate3d(0, 0px, 0)',
      opacity: 1,
    },
    from: {
      transform: 'translate3d(0, -15px, 0)',
      opacity: 0,
    },
    leave: {
      transform: 'translate3d(0, -15px, 0)',
      opacity: 0,
    },
  })

  const menuWidth = props.menuWidth ? { width: props.menuWidth } : {}
  const defaultStyles = {
    ...props.style,
    ...menuWidth,
  }

  return (
    <>
      {transitions(
        (animation, showAnimation) =>
          showAnimation && (
            <animated.div
              aria-expanded={isVisible}
              className={`${props.className} transition-all duration-150 border bg-white rounded-md absolute z-40`}
              style={{ ...animation, ...defaultStyles }}
              ref={menuRef}
              onClick={(e) => e.stopPropagation()}>
              {props.children}
            </animated.div>
          ),
      )}
    </>
  )
}
