import { keyBy } from 'lodash/fp'

export const DEV_PRICE_IDS = {
  EVENT: {
    MONTHLY: 'price_1JJ3rHKUoUu4ywBBIJuuhKbr',
    WEEKLY: 'price_1JNuRTKUoUu4ywBBRvLwb9PT',
  },
  GROUP_CLASS: {
    1: 'price_1Je7ZwKUoUu4ywBBFtChJIzL',
    2: 'price_1JNuO5KUoUu4ywBBBvpQRCbR',
    3: 'price_1Je7bVKUoUu4ywBBanGS1wVM',
    4: 'price_1JNuPDKUoUu4ywBBXi6LLLRg',
    5: 'price_1Je7c6KUoUu4ywBBeTDEnsF6',
    6: 'price_1JR5NCKUoUu4ywBBS72aN4rd',
    7: 'price_1Je7d3KUoUu4ywBBqvaelBJK',
    8: 'price_1JNuPxKUoUu4ywBBhEKj8yvx',
  },
}

export const PROD_PRICE_IDS = {
  MEMBERSHIP: 'price_1JpE1GKUoUu4ywBBnOCcYSff',
  DANCE_MEMBERSHIP: 'price_1MhHzuKUoUu4ywBBKRUQYsRV',
  EVENT: {
    MONTHLY: 'price_1Lks34KUoUu4ywBBhugXBdzR',
    WEEKLY: 'price_1JRNqTKUoUu4ywBBJjgnp94o',
  },
  GROUP_CLASS: {
    1: 'price_1Je7efKUoUu4ywBBS8B4FvL0',
    2: 'price_1JRNrIKUoUu4ywBBl9DcDG5p',
    3: 'price_1Je7e8KUoUu4ywBB0ikTpyQS',
    4: 'price_1JRNrDKUoUu4ywBBmKDEZsxA',
    5: 'price_1Je7dkKUoUu4ywBBTbtPqNmX',
    6: 'price_1JRNpiKUoUu4ywBBRyeCjhmS',
    7: 'price_1Je7dDKUoUu4ywBBFSWmj6zE',
    8: 'price_1JRNr8KUoUu4ywBBKHff8uXl',
  },
}

export const PRICE_IDS = {
  DEV: DEV_PRICE_IDS,
  PROD: PROD_PRICE_IDS,
}

export const STRIPE_PRICE_IDS = {
  DEV: {
    MEMBERSHIP: 'price_1JpE1GKUoUu4ywBBnOCcYSff',
    DANCE_MEMBERSHIP: 'price_1MhHzuKUoUu4ywBBKRUQYsRV',
    HARMONIZERS_DANCE: 'price_1MhHwqKUoUu4ywBB6BuKTQmV',
    MONTHLY_EVENT: 'price_1JJ3rHKUoUu4ywBBIJuuhKbr',
    CHELSEA_REED: 'price_1LE4R7KUoUu4ywBBvwjOrERW',
    WEEKLY_EVENT: 'price_1JNuRTKUoUu4ywBBRvLwb9PT',
    CLASS_2_WEEKS: 'price_1JNuO5KUoUu4ywBBBvpQRCbR',
    CLASS_4_WEEKS: 'price_1JNuPDKUoUu4ywBBXi6LLLRg',
    CLASS_6_WEEKS: 'price_1JR5NCKUoUu4ywBBS72aN4rd',
    CLASS_8_WEEKS: 'price_1JNuPxKUoUu4ywBBhEKj8yvx',
  },
  PROD: {
    // MONTHLY_EVENT: 'price_1JRNrOKUoUu4ywBBRDnWBlQR',
    MONTHLY_EVENT: 'price_1Lks34KUoUu4ywBBhugXBdzR',
    WEEKLY_EVENT: 'price_1JRNqTKUoUu4ywBBJjgnp94o',
    HARMONIZERS_DANCE: 'price_1MhHwqKUoUu4ywBB6BuKTQmV',
    CHELSEA_REED: 'price_1LE4R7KUoUu4ywBBvwjOrERW',
    CLASS_2_WEEKS: 'price_1JRNrIKUoUu4ywBBl9DcDG5p',
    CLASS_4_WEEKS: 'price_1JRNrDKUoUu4ywBBmKDEZsxA',
    CLASS_6_WEEKS: 'price_1JRNpiKUoUu4ywBBRyeCjhmS',
    CLASS_8_WEEKS: 'price_1JRNr8KUoUu4ywBBKHff8uXl',
    MEMBERSHIP: 'price_1JpE1GKUoUu4ywBBnOCcYSff',
    DANCE_MEMBERSHIP: 'price_1MhHzuKUoUu4ywBBKRUQYsRV',
  },
}

export const PRICES = {
  MONTLY_EVENT: 20,
  WEEKLY_SOCIAL: 10,
  CHELSEA_REED: 20,
  PER_GROUP_CLASS: {
    STUDENT: 15,
    BEGINNER: 20,
    NORMAL: 25,
    MEMBERSHIP: 30,
  },
  CLASS_MEMBERSHIP: 120,
  DANCE_MEMBERSHIP: 48,
  PER_PRIVATE: {
    SINGLE: 80,
    STUDENT: 60,
  },
}

export const COSTS = {
  INSTRUCTORS: 25,
  EMPLOYEE: 20,
  VENUE: {
    WEEK_DAY: 30,
    WEEKEND: 50,
    SECURITY: 100,
  },
  BAND: 1000,
  FLAT_MONTHLY: {
    ADVERTISING: 1000,
  },
}

export const ADDRESS_DESCRIPTION =
  'We are located at the Link Auditorium in Central West End!  It is a historic venue whose owners share our views on the importance of dance, art, and culture.'

export const GROUP_CLASS_ADDRESS = '4504 Westminster Pl, St. Louis, MO 63108'

export const CASA_LOMA_ADDRESS_DESCRIPTION =
  "For this event, we have rented out the Casa Loma ballroom.  It's got almost 100 years of history and the best floor for dancing in STL."

export const CASA_LOMA_ADDRESS = '3354 Iowa Ave, St. Louis, MO 63118'

export const CLASS_TIMES = {
  '6:30pm - 7:30pm': 'T18:30:00',
  '7:30pm - 8:30pm': 'T19:30:00',
  // '6:30pm - 7:30pm': 'T18:30:00-05:00Z',
  // '7:30pm - 8:30pm': 'T19:30:00-05:00Z',
}

export const DESCRIPTIONS = {
  CLASS: {
    GLOBAL: `Each class in this series is ${
      PRICES.PER_GROUP_CLASS.NORMAL + ' '
    }/ hour.
    Each class includes 1 hour of instruction and includes free entry into our weekly social dance(normally $10).
    Our weekly social is on Wednesdays from 8:30pm - 10:30pm.`,
    // If you are in school, we offer a 50% discount.
  },
}

export const IMAGES = {
  DEFAULT: {
    person:
      'https://urbaned.tcnj.edu/wp-content/uploads/sites/85/2019/10/placeholder-profile-1.png',
  },
}

export const HOURS = {
  630: 'T18:30:00',
  730: 'T19:30:00',
  830: 'T20:30:00',
  930: 'T21:30:00',
  1100: 'T23:00:00',
  1200: 'T24:00:00',
}

export const CLASS_VIDEOS = [
  'erin-chorus-girl-clip.mp4',
  'bachata-promo.mp4',
  'old-tap-video.mp4',
  'ilhc-mobile.mp4',
  'skye-frida-swingouts.mp4',
  'josh-video.mp4',
  'solo-jazz.mp4',
  'battle-allskate.mp4',
  'lindy-vocab-1.mp4',
  'thalia-video.mp4',
  'cam-liz-charleston.mp4',
  'liz-video.mp4',
  'xander-video.mp4',
  'kenneth-blues.mp4',
  'website-stl-shag.mp4',
] as const

export const GROUP_CLASS_TIMESLOTS = ['6:30pm - 7:30pm', '7:30pm - 8:30pm']

export const EXPERIENCE_LEVEL = [
  'Brand New',
  'Under 2 Months',
  '2 - 6 Months',
  'Over 6 Months',
  'Over 2 Years',
  'Over 5 Years',
]

export const DANCE_FORMS = [
  'Bachata',
  'Lindy Hop',
  'Balboa',
  'STL Shag',
  'Solo Jazz',
  'Blues',
  'Collegiate Shag',
  'Chorus Girls',
  // 'Swing Aerials',
] as const

export const GROUP_CLASS_INSTRUCTORS = [
  'Cameron & Liz',
  'Josh & Christina',
  'Xander & Thalia',
  'Jony',
] as const

export const GROUP_CLASS_LEVELS = [
  'All Levels',
  'Beginner',
  'Intermediate',
  'Advanced',
] as const

export const QRCODES = {
  'Blue Background': 'bg-blue-code.png',
  Blue: 'blue-code.png',
  'Blue / Red': 'gradient-code.png',
  Green: 'green-code.png',
  Orange: 'orange-code.png',
  Pink: 'pink-code.png',
  Purple: 'purple-code.png',
  Red: 'red-code.png',
}

export const URLS = {
  DEV: 'http://localhost:3000',
  STAGING: 'https://stlswingdev.web.app',
  PROD: 'https://stlswing.dance',
}

export const TEACHERS = [
  {
    name: 'Cameron',
    color: 'red-500',
    bg: 'bg-red-500',
    gradient: 'from-red-500',
    id: '4GeoSDWXEhct75Pq1Vda2Gte8Bf2',
  },
  {
    name: 'Liz',
    color: 'red-500',
    bg: 'bg-red-500',
    gradient: 'to-red-500',
    id: 'AWnjPP4WGpOHpdS3MwD8GdY8mhp2',
  },
  {
    name: 'Christina',
    color: 'purple-500',
    bg: 'bg-purple-500',
    gradient: 'to-purple-500',
    id: 'XXEoVPUZzAXwdpPzO41edBVVZAn2',
  },
  {
    name: 'Josh',
    color: 'purple-500',
    bg: 'bg-purple-500',
    gradient: 'to-purple-500',
    id: 'qyxHddLuExXA7JEg8O7zB2Nz2ew2',
  },
  {
    name: 'Xander',
    color: 'green-500',
    bg: 'bg-green-500',
    gradient: 'from-green-500',
    id: 'stgkuKQ2ZRczwlD7IawP42NXzWK2',
  },
  {
    name: 'Thalia',
    color: 'green-500',
    bg: 'bg-green-500',
    gradient: 'from-green-500',
    id: 'KuJkXrEGlcR5WOsv9BP7y2lE1kq1',
  },
  {
    name: 'Erin',
    color: 'pink-500',
    bg: 'bg-pink-500',
    gradient: 'from-pink-500',
    id: 'a5LmICWEoKPgpZCXtHdTjrIgg523',
  },
  {
    name: 'Kenneth',
    color: 'blue-500',
    bg: 'bg-blue-500',
    gradient: 'from-blue-500',
    id: 'UtoT9cIZqNghN2IlzgvCWMpxrPm1',
  },
  {
    name: 'Marina',
    color: 'slate-500',
    bg: 'bg-slate-500',
    gradient: 'from-slate-500',
    id: 'L0Svrmnp36Omp7G1wpDgX96c3nw2',
  },
  {
    name: 'Christian',
    color: 'slate-500',
    bg: 'bg-slate-500',
    gradient: 'from-slate-500',
    id: 'msC5lzcCEIaxIetMZbOdVIL78sI2',
  },
  {
    name: 'Jony',
    color: 'slate-500',
    bg: 'bg-slate-500',
    gradient: 'from-slate-500',
    id: 'H1UoKUE5SHUpTqxTm2YBjOolFFf1',
  },
]

export const TEACHERS_MAP = keyBy('id', TEACHERS)
