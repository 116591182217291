import React, { useState, useEffect } from 'react'
import { PageHeading } from 'app/layout/PageHeading'
import { Tabs } from 'app/shared/components'
import { useAnalytics } from 'app/shared/hooks/useAnalytics'

const spotifyPlaylists = [
  {
    title: "Not Your Daddy's Swing Playlist",
    description:
      'This stuff swings and it swings hard.  If you can swing out to these songs, then you can consider yourself a solid dancer',
    link: 'https://open.spotify.com/playlist/52tQVUHTxAwtfi84649oyc?si=3KBIKJTYQG6PXrhXkysclw',
    embed: 'https://open.spotify.com/embed/playlist/52tQVUHTxAwtfi84649oyc',
  },
  {
    title: 'Basic Low-Mid Tempo Swing',
    description:
      'Good music for practicing the basics.  The songs are ordered by speed.',
    link: 'https://open.spotify.com/playlist/4FSa3kgf4Exh2OVTLlnrov?si=-Mu-Kcg5RW2A0RDRLEVhsA',
    embed: 'https://open.spotify.com/embed/playlist/4FSa3kgf4Exh2OVTLlnrov',
  },
  {
    title: 'Basic Mid-High Tempo Swing',
    description:
      'A great playlist for getting your feet wet with fast music.  The songs are in order of speed.',
    link: 'https://open.spotify.com/playlist/7gLSgmHQCQbM5kdDR1SMMW?si=ue3KgGS3Q2WLJ29hDbKqnw',
    embed: 'https://open.spotify.com/embed/playlist/7gLSgmHQCQbM5kdDR1SMMW',
  },
  {
    title: 'Slower Chill Swing',
    description:
      'Great music for when you just want to chill and dance and not worry about atheleticism or perfect technique',
    link: 'https://open.spotify.com/playlist/4xaRToxmYTgtUH3pbA3dGM?si=SNEfFpujR4OajE5BqmU9Jw',
    embed: 'https://open.spotify.com/embed/playlist/4xaRToxmYTgtUH3pbA3dGM',
  },
  {
    title: 'Misc Swing Music',
    description:
      'This is just a conglomeration of random swing songs.  Listen and see if you like anything!',
    link: 'https://open.spotify.com/playlist/3WUDxuvH6dsKx5CPDUnY98?si=M2Xas1jJR2GmuH8KjLzx3g',
    embed: 'https://open.spotify.com/embed/playlist/3WUDxuvH6dsKx5CPDUnY98',
  },
]

const classRecapsList = [
  {
    name: '6-Count Movements',
    youtubeVideo: 'https://www.youtube.com/embed/tHfOwKmbYVw',
    description:
      'The very basics:  movements done over 6 counts.  Lindy Hop is not a 6 count dance, but 6-count moves are simple and will allow you to have fun dances with a variety of people.',
    level: 'Beginner',
  },
  {
    name: '8-Count Movements',
    youtubeVideo: 'https://www.youtube.com/embed/22ixi8sjqTY',
    description:
      "Movements that are done over 8-counts.  That's 2 bars for you music nerds",
    level: 'Beginner',
  },
  {
    name: 'Basic Charleston',
    youtubeVideo: 'https://www.youtube.com/embed/22ixi8sjqTY',
    description:
      'A high enery dance that is often integrated into Lindy Hop.  It involves a lot of flashy movements with big kicks and hops.',
    level: 'Beginner',
  },
  {
    name: 'Swingouts: Level 1',
    youtubeVideo: 'https://www.youtube.com/embed/SHCjrRE7BF8',
    description:
      'The swing out is the quintissential Lindy Hop movement.  This recap goes over a class that focused on it.',
    level: 'Intermediate',
  },
  {
    name: 'Swingouts: Level 2',
    youtubeVideo: 'https://www.youtube.com/embed/que_ka9eqQA',
    description:
      'The swing out is the quintissential Lindy Hop movement.  This recap is from a class teaching more advanced concepts about the swing out.',
    level: 'Intermediate',
  },
  {
    name: 'Vocabulary 1',
    youtubeVideo:
      'https://www.youtube.com/embed/videoseries?list=PL9NYzfVwgE2f5RUX0-bHdAqgSXfQPaT3x',
    description:
      'This is a more advanced class teaching various vocabulary moves used within Lindy Hop',
    level: 'Elective',
  },
  {
    name: 'Dips and Tricks',
    youtubeVideo: 'https://www.youtube.com/embed/BInCZvtjxCw',
    description:
      'This is a more advanced class teaching various dips and tricks.',
    level: 'Elective',
  },
  {
    name: 'St Louis Shag',
    youtubeVideo: 'https://www.youtube.com/embed/pMc_fB-oar4',
    description:
      'St Louis Shag is a dance formed and created in St Loius.  This is a class dedicated to teaching it.',
    level: 'Elective',
  },
  {
    name: 'Swing Out Variations',
    youtubeVideo: 'https://www.youtube.com/embed/YjaJC33FtJQ',
    description:
      'This class teaching various ways you can change a swing out to add flair.',
    level: 'Elective',
  },
  {
    name: 'Partnering',
    youtubeVideo: 'https://www.youtube.com/embed/UBxyy5foXoM',
    description: 'This class teaches more advanced partnering concepts.',
    level: 'Elective',
  },
  {
    name: 'Balboa',
    youtubeVideo: 'https://www.youtube.com/embed/BMEcvBCIYWs',
    description:
      'This class teaches balboa, a dance done in close embrace to fast music.',
    level: 'Elective',
  },
]

export const ClassResourcesPage = () => {
  const { pageViewEvent } = useAnalytics()

  const [tabs, setTabs] = useState({
    'Practice Videos': true,
    'Practice Music': false,
  })

  useEffect(() => {
    pageViewEvent('Class Resources')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getLevelColor = (level: string) => {
    if (level === 'Beginner') return 'bg-blue-200'
    if (level === 'Intermediate') return 'bg-green-200'
    if (level === 'Elective') return 'bg-yellow-200'
    return 'bg-teal-200'
  }

  return (
    <section className=' relative z-0 bg-gray-200'>
      <PageHeading title='Class Resources' image='images/class-recap-img.jpg' />

      <div className='sm:px-8'>
        {/*  @ts-ignore fuckin dumb */}
        <Tabs tabs={tabs} setTabs={setTabs} />
      </div>

      <ul className='sm:px-8 sm:grid-cols-2 lg:grid-cols-3 grid grid-cols-1 gap-6 px-2 my-10'>
        {tabs['Practice Videos'] &&
          classRecapsList.map((recap, i) => (
            <li className='flex flex-col justify-between col-span-1 bg-white divide-y divide-gray-200 rounded-lg shadow'>
              <div className='flex items-center justify-between w-full p-6 space-x-6'>
                <div className='flex-1'>
                  <div className='flex items-center space-x-3'>
                    <h3 className='text-sm font-medium text-gray-900 truncate'>
                      {recap.name}
                    </h3>
                    <span
                      className={`flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium ${getLevelColor(
                        recap.level,
                      )} rounded-full`}>
                      {recap.level}
                    </span>
                  </div>
                  <p className='mt-1 text-sm text-gray-500'>
                    {recap.description ? recap.description : ''}
                  </p>
                </div>
              </div>

              <a
                className='hover:bg-gray-100 flex justify-center py-4 transition-all duration-300 bg-orange-200'
                href={recap.youtubeVideo}
                rel='noreferrer'
                target='_blank'>
                <span className='font-medium'>View Recap</span>
              </a>
            </li>
          ))}

        {tabs['Practice Music'] &&
          spotifyPlaylists.map((playlist) => (
            <li className='flex flex-col justify-between col-span-1 bg-white divide-y divide-gray-200 rounded-lg shadow'>
              <div className='flex items-center justify-between w-full p-6 space-x-6'>
                <div className='flex-1'>
                  <div className='flex items-center space-x-3'>
                    <h3 className='text-sm font-medium text-gray-900 truncate'>
                      {playlist.title}
                    </h3>
                  </div>
                  <p className='mt-1 text-sm text-gray-500'>
                    {playlist.description}
                  </p>
                </div>
              </div>

              <a
                className='hover:bg-gray-100 flex justify-center py-4 transition-all duration-300 bg-green-200'
                href={playlist.link}
                rel='noreferrer'
                target='_blank'>
                <span className='font-medium'>View Playlist</span>
              </a>
            </li>
          ))}
      </ul>
    </section>
  )
}

export default ClassResourcesPage
