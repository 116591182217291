import { Button } from 'app/shared/components'
import { Video } from 'app/shared/components/Media/Video'
import { useRouter, useUI } from 'app/shared/hooks'
import React from 'react'

export const Hero = () => {
  const { navigateTo } = useRouter()
  const { isMobile } = useUI()

  return (
    <>
      <div className='w-full h-screen bg-gray-700'>
        <Video
          src={!isMobile ? 'images/ilhc-2013.mp4' : 'videos/ilhc-mobile.mp4'}
          fit='cover'
          alt=''
          className='w-full h-full'>
          <div className='flex flex-col place-items-center content-center justify-center w-full h-full bg-transparent'>
            <div className='p-10 m-10 bg-orange-800 bg-opacity-50 rounded-lg backdrop-filter backdrop-blur-sm space-y-2 sm:space-y-0'>
              <h1 className='text-4xl font-bold text-white sm:text-6xl font-large'>
                STL Swing Dance
              </h1>
              <p className='flex text-lg font-medium text-white'>
                Learn To Dance. Meet New People. Get In Shape.
              </p>
              <div className='space-y-2 space-x-1 sm:space-x-2 sm:py-4'>
                <Button
                  variant='raised'
                  data-testid='hero-my-classes-button'
                  onClick={() =>
                    navigateTo('/swing-dance-classes/groupClasses')
                  }>
                  View Classes
                </Button>
                <Button
                  variant='raised'
                  data-testid='hero-my-events-button'
                  onClick={() => navigateTo('/swing-dance-events')}>
                  View Events
                </Button>
              </div>
            </div>
          </div>
          <p className='text-gray-200 text-xs -mt-16 z-50 p-4'>
            Video taken at 2013 International Lindy Hop Championships
          </p>
          <p className='text-gray-200 text-xs -mt-8 z-50 p-4'>
            Videographer - Alain Wong
          </p>
        </Video>
      </div>
    </>
  )
}
