import React, { useEffect } from 'react'
import { Hero } from 'app/stlswing/components/Home/Hero'
import { Video } from 'app/shared/components/Media/Video'
import {
  WhatIsSwingDancingPanel,
  MeetTheTeachersPanel,
  LearnToDancePanel,
} from '../components/Home'
import { Button } from 'app/shared/components'
import { useRouter } from 'app/shared/hooks'
import { useAnalytics } from 'app/shared/hooks/useAnalytics'

export const HomePage = () => {
  const { navigateTo } = useRouter()
  // const { pageViewEvent } = useAnalytics()

  // useEffect(() => {
  //   pageViewEvent('STL Swing Dance')
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <section>
      <Hero />
      <WhatIsSwingDancingPanel />
      <Video
        src='videos/battle-allskate.mp4'
        className='w-full h-screen'
        alt=''
        fit='cover'
      />
      <LearnToDancePanel />
      {/* <MeetTheTeachersPanel /> */}
      <section className='p-2 px-4 bg-gray-800'>
        <h2 className='max-w-3xl py-16 mx-auto mt-6 text-xl text-white'>
          Our goal is to transform swing dance from a niche hobby to a fixture
          of St Louis nightlife
        </h2>
      </section>
      <section className='sm:hidden flex justify-center'>
        <div className='m-2'>
          <Button
            variant='raised'
            className='m-5 ml-0'
            onClick={() => navigateTo('/swing-dance-classes/groupClasses')}>
            View Classes
          </Button>
          <Button
            variant='raised'
            className='m-5 mt-1 ml-0'
            onClick={() => navigateTo('/swing-dance-events')}>
            View Events
          </Button>

          {/* <Button variant='raised'>Go Dancing</Button> */}
        </div>
      </section>
      {/* <Newsletter /> */}
    </section>
  )
}

export default HomePage
