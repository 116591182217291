import React, { useContext, useEffect, useState } from 'react'
import { Mask } from './Mask'
import {
  useKeybind,
  useLockBodyScroll,
  useRouter,
  useWindowSize,
} from '../../../hooks'
import { getModalTransition } from '../modal.helpers'
import { useTransition, animated } from 'react-spring'
import { ModalHeader } from './ModalHeader'
import usePrevious from 'app/shared/hooks/usePrevious'
import { equals } from 'ramda'
import { UIContext } from 'app/shared/context/UIContext'
import { useDebouncedFadeAnimation } from './hooks/useDebouncedFadeAnimation'

export type ModalType =
  | 'modal'
  | 'right-panel'
  | 'left-panel'
  | 'top-panel'
  | 'bottom-panel'
export type ModalPosition = 'absolute' | 'inline'

export type ModalTypes = `${ModalPosition} ${ModalType}` | ModalType

export type ModalProps = {
  children: any
  isOpen: boolean
  title?: string | string[] | React.ReactNode
  onClose: () => void
  type?: ModalTypes
  buttonClasses?: string
  titleClasses?: string
  hideTitle?: boolean
  allowRouting?: boolean
  disallowRouting?: boolean
}

export const Modal = ({
  children,
  isOpen,
  title,
  type = 'left-panel',
  ...props
}: C<ModalProps>) => {
  const [visible, setVisible] = useState(isOpen)
  const { history, currentRoute, redirectTo, location } = useRouter()
  const previousRoute = usePrevious(currentRoute)
  const [height, width] = useWindowSize()

  useLockBodyScroll(visible)
  useKeybind(['Escape'], () => onClose())

  // Animations
  const debouncedFadeIn = useDebouncedFadeAnimation(visible)
  const animation = useTransition(visible, {
    config: { duration: 200 },
    ...getModalTransition(type),
  })

  // Hide Global Header When Visible
  const { setShowHeader } = useContext(UIContext)
  useEffect(() => {
    if (visible) setShowHeader(false)
    if (!visible) setShowHeader(true)
  }, [visible])

  // If parent open/close state changes, set component state to parent
  useEffect(() => {
    setVisible(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (!visible) return
    if (!equals(previousRoute, currentRoute)) onClose()
  }, [currentRoute, previousRoute])

  useEffect(() => {
    if (props.disallowRouting) return
    if (!props.allowRouting) return
    if (visible) return
    const queryParamText = `?=${transformTitleToQueryParams(title)}`

    if (location.search === queryParamText) return setVisible(true)
  }, [visible, location.search, currentRoute, isOpen])

  useEffect(() => {
    if (!visible) return

    const queryParamText = `?=${transformTitleToQueryParams(title)}`

    history.push({
      pathname: currentRoute,
      search: queryParamText,
    })

    const onBack = history.listen(() => {
      if (history.action === 'POP') {
        onClose()
        // return false
      }
      return true
    })

    return () => {
      onBack()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, isOpen])

  const onClose = () => {
    redirectTo(location.pathname)
    setVisible(false)
    props.onClose()
  }

  const transformTitleToQueryParams = (title: string | any) =>
    typeof title === 'string'
      ? title
          .split(' ')
          .map((i) => i.toLowerCase())
          .join('-')
      : 'modal'

  return (
    <>
      {animation(
        (styles, isOpen) =>
          isOpen && (
            <animated.div
              style={{ ...styles, height: height, maxHeight: height }}
              className={`${getClasses(type)} ${props.className}`}>
              {/* Title(optional) */}
              {!props.hideTitle && title && typeof title === 'string' && (
                <ModalHeader
                  title={typeof title === 'string' ? title : 'modal'}
                  onClose={onClose}
                  titleClasses={props.titleClasses}
                  buttonClasses={props.buttonClasses}
                />
              )}

              {/* Modal Content */}
              {debouncedFadeIn(
                (styles, isDebouncing) =>
                  !isDebouncing && (
                    <animated.div style={styles}>{children}</animated.div>
                  ),
              )}
            </animated.div>
          ),
      )}

      <Mask isVisible={visible} onClick={onClose} />
    </>
  )
}

export default Modal

const getPanelClasses = (type: ModalTypes) => {
  if (type === 'left-panel') return `top-0 left-0`
  if (type === 'right-panel') return `top-0 right-0`
  if (type === 'bottom-panel') return `bottom-0 right-0 w-screen`
  return `top-0 left-0 h-screen`
}

const getClasses = (type: ModalTypes) => {
  const baseClasses = 'fixed bg-white z-50 resize-x'
  const panelClasses = getPanelClasses(type)
  return `${baseClasses} ${panelClasses}`
}
