import React, { useEffect, useState } from 'react'
import { animated, useTransition } from 'react-spring'
import { indexOf, take, map, head, tail, equals, any } from 'ramda'
import { faChevronRight, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'app/shared'
import { capitalize } from 'lodash/fp'
import { sentenceCase } from 'change-case'

type Props = {
  titles: string[]
  onClickHome?: () => void
  onClickTitle: (title: string) => void
}
export const Breadcrumbs = (props: C<Props>) => {
  const [crumbs, setCrumbs] = useState<string[]>([])
  const { location, redirectTo } = useRouter()

  useEffect(() => {
    if (props.titles.length > 1)
      setCrumbs(
        tail(
          location.pathname
            .split('/')
            .map((v) => (v === 'swing-dance-classes' ? 'classes' : v))
            .map((title) =>
              sentenceCase(title).split(' ').map(capitalize).join(' '),
            )
            .map((title, i) => {
              if (i === 3) return 'Sign Up'
              if (i === 2) {
                if (crumbs[0]?.includes('Events')) return 'Sign Up'
                return title
              }
              return title
            })
            .slice(0, 3),
        ),
      )
  }, [props.titles])

  const animation = useTransition(crumbs, {
    from: {
      opacity: 0,
      transform: 'translateX(-20%)',
    },
    enter: {
      opacity: 1,
      transform: 'translateX(0%)',
    },
  })

  return (
    <div>
      <ol className='flex items-center'>
        {/* Home Button */}
        <li>
          <button
            className='flex items-center justify-center'
            onClick={() => redirectTo('/home')}>
            <FontAwesomeIcon
              icon={faHome}
              className='h-7 w-7 text-white mx-2'
            />
          </button>
        </li>

        {/* Replacement '...' for longer breadcrumbs  */}
        {crumbs.length > 3 && (
          <li className='flex items-center'>
            <div className='flex text-white items-center'>...</div>
          </li>
        )}

        {animation((style, title) => (
          <animated.li style={style}>
            <div className='flex items-center space-x-1'>
              {indexOf(title, props.titles) !== 0 && (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className='w-5 h-5 text-white mx-2'
                />
              )}

              <button
                onClick={() => props.onClickTitle(title)}
                className={`text-sm font-medium ${
                  indexOf(title, crumbs) === crumbs.length - 1
                    ? 'flex grow'
                    : 'truncate'
                } sm:w-full text-white`}>
                {title}
              </button>
            </div>
          </animated.li>
        ))}
      </ol>
    </div>
  )
}
