import React from 'react'
// prettier-ignore
import { pipe, keys, mapObjIndexed, set, lensProp, equals, pickBy, F } from 'ramda'

type Props<T> = {
  tabs: T
  setTabs: SetState<T>
}

export const Tabs = <T extends Record<any, boolean>>({
  tabs,
  setTabs,
}: Props<T>) => {
  const changeTab = (tabName: keyof typeof tabs) =>
    setTabs(
      pipe(
        mapObjIndexed(F), // set all tabs to false
        // @ts-ignore not sure why this is happening
        set(lensProp(tabName), true), // set chosen tab to true
      ),
    )

  type ArrayInfer<T> = T extends (infer U)[] ? U : never
  function head<T extends any[]>(list: T) {
    return list[0] as ArrayInfer<T>
  }
  const selectedTab = pipe(pickBy(equals(true)), keys, head)

  return (
    <div className=''>
      <div className='hidden w-full px-2'>
        <label htmlFor='tabs' className='sr-only'>
          Select a tab
        </label>
        <select
          id='tabs'
          name='tabs'
          className='focus:ring-orange-500 focus:border-orange-500 w-full h-12 p-2 mt-2 border-gray-300 rounded-md'
          onChange={(e) => changeTab(e.target.value)}
          defaultValue={selectedTab(tabs)}>
          {keys(tabs).map((name) => (
            // @ts-ignore
            <option key={name}>{name}</option>
          ))}
        </select>
      </div>
      <div className='w-full'>
        <div className='border-b border-gray-200'>
          <nav
            className='flex justify-center -mb-px space-x-2 min-w-fit'
            aria-label='Tabs'>
            {keys(tabs).map((name) => (
              <button
                // @ts-ignore
                key={name}
                onClick={() => changeTab(name)}
                className={`
                  ${
                    !!tabs[name]
                      ? 'border-orange-500 text-orange-600 '
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 '
                  } w-1/4 py-4 px-1 text-center border-b-2 font-medium text-md min-w-fit
                `}
                aria-current={!!tabs[name] ? 'page' : undefined}>
                {name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}
