export const prodEnvironment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyBPUAoSuOdLRfg7LR1Oey-MQFFHj6ExGgU',
    authDomain: 'stlswing.dance',
    projectId: 'stl-swing-dance',
    storageBucket: 'stl-swing-dance.appspot.com',
    messagingSenderId: '683859120154',
    appId: '1:683859120154:web:354883694379ca7b5801a3',
    measurementId: 'G-MC5XJDYGJ5',
  },
}

export default prodEnvironment
