import React from 'react'
import { CloseIcon } from '../../Icons/CloseIcon'

type Props = {
  title?: string
  onClose: () => void
  buttonClasses?: string
  titleClasses?: string
}

export const ModalHeader = ({ title, onClose, ...props }: C<Props>) => (
  <header
    className={`w-full border-b flex justify-between items-center px-2 h-20 ${props.className}`}>
    <h2
      className={`sm:text-2xl flex items-center text-2xl font-extrabold ${props.titleClasses}`}>
      {title}
    </h2>
    <button onClick={onClose} className={props.buttonClasses} type='button'>
      <CloseIcon className='w-10 h-10' />
    </button>
  </header>
)
