import { fromEvent } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import { useObservable } from 'rxjs-hooks'

export const useUI = () => {
  const isMobile = useObservable(
    () =>
      fromEvent(window, 'resize').pipe(
        debounceTime(100),
        map(() => window.innerWidth <= 750),
      ),
    window.innerWidth <= 750,
  )

  /**
   *  Checks whether the current browser is a webview from apple
   *  i.e. the user has been linked from facebook on their iphone)
   * @returns boolean
   */
  const isWebview = () =>
    /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent)

  return {
    isMobile,
    isWebview,
  }
}
