import React, { createContext, useState } from 'react'

type Context = {
  showHeader: boolean
  setShowHeader: SetState<boolean>
  showFooter: boolean
  setShowFooter: SetState<boolean>
}
const defaultContext: Context = {
  showHeader: true,
  setShowHeader: () => {},
  showFooter: true,
  setShowFooter: () => {},
}

export const UIContext = createContext<Context>(defaultContext)
export const UIProvider: FC<{}> = ({ children }) => {
  const [showHeader, setShowHeader] = useState(true)
  const [showFooter, setShowFooter] = useState(true)

  return (
    <UIContext.Provider
      value={{ showHeader, setShowHeader, showFooter, setShowFooter }}>
      {children}
    </UIContext.Provider>
  )
}
