import { useState, useCallback } from 'react'

export const useToggle = (initialState = false) => {
  const [state, setToggleState] = useState(initialState)

  const toggle = useCallback(() => setToggleState(v => !v), [])
  const setTrue = useCallback(() => setToggleState(true), [])
  const setFalse = useCallback(() => setToggleState(false), [])

  return { state, toggle, setTrue, setFalse, setToggleState }
}
