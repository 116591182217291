import { useState } from 'react'
import { getAnalytics, setCurrentScreen, logEvent } from 'firebase/analytics'
import { firebaseApp } from 'environments'

const FACEBOOK_PIXEL_ID = '275796870819755'

type FacebookStandardEvents =
  | 'AddPaymentInfo'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'CompleteRegistration'
  | 'Contact'
  | 'CustomizeProduct'
  | 'Donate'
  | 'FindLocation'
  | 'InitiateCheckout'
  | 'Lead'
  | 'Purchase'
  | 'Schedule'
  | 'Search'
  | 'StartTrial'
  | 'SubmitApplication'
  | 'Subscribe'
  | 'ViewContent'

type FacebookEventData = {
  content_category: string
  content_ids: string[]
  content_name: string
  contents: object[]
  currency: 'USD'
  value: number
  num_items: number
  search_string: string
  predicted_ltv: string
  content_type: string
  status: boolean
}

type EventProps<
  T extends keyof FacebookEventData,
  Required = false,
> = Required extends true
  ? Pick<FacebookEventData, T>
  : Partial<Pick<FacebookEventData, T>>

// prettier-ignore
type GetFacebookEventData<T extends FacebookStandardEvents> = T extends 'AddPaymentInfo'
  ? EventProps<'content_category' | 'content_ids' | 'content_name' | 'currency' | 'value'>
  : T extends 'AddToCart'
  ? EventProps<'content_ids' | 'content_name' | 'content_type' | 'contents' | 'currency' | 'value'>
  : T extends 'AddToWishlist'
  ? EventProps<'content_name'|'content_category'|'content_ids'|'contents'|'currency'|'value'>
  : T extends 'CompleteRegistration'
  ? EventProps<'content_name' | 'status' | 'currency' | 'value'>
  : T extends 'InitiateCheckout'
  ? EventProps<'content_category' | 'content_ids' | 'contents' | 'currency' | 'num_items' | 'value'>
  : T extends 'Lead'
  ? EventProps<'content_category' | 'content_name' | 'currency' | 'value'>
  : T extends 'Purchase'
  ? EventProps<'currency' | 'content_ids' | 'content_name' | 'content_type' | 'value', true> & EventProps<'contents' | 'num_items'>
  : T extends 'Search'
  ? EventProps<'content_category' | 'content_ids' | 'search_string' | 'currency' | 'value' | 'contents'>
  : T extends 'StartTrial'
  ? EventProps<'currency' | 'predicted_ltv' | 'value'>
  : T extends 'Subscribe'
  ? EventProps<'currency' | 'predicted_ltv' | 'value'>
  : T extends 'ViewContent'
  ? EventProps<'content_category' | 'content_ids' | 'content_name', true> & EventProps<'contents' | 'currency' | 'value'>
  : Partial<{}>

export type ContentCategory =
  | 'group-class'
  | 'social-event'
  | 'private-lesson'
  | 'beginner-class'
  | 'intermediate-class'
  | 'advanced-class'

export const useAnalytics = () => {
  const [firebaseAnalytics] = useState(getAnalytics(firebaseApp))

  // const trackSingleEvent = <T extends FacebookStandardEvents>(
  //   event: T,
  //   data?: GetFacebookEventData<T>,
  // ) => ReactPixel.trackSingle(FACEBOOK_PIXEL_ID, event, data)

  const pageViewEvent = (title: string) => {
    // ReactPixel.init(FACEBOOK_PIXEL_ID)
    // ReactPixel.pageView()
    setCurrentScreen(firebaseAnalytics, title)
    logEvent(firebaseAnalytics, 'page_view', { page_title: title })
  }

  const purchaseEvent = (
    purchaseEventData: Omit<GetFacebookEventData<'Purchase'>, 'currency'>,
  ) => {
    logEvent(firebaseAnalytics, 'purchase')
    // trackSingleEvent('Purchase', { currency: 'USD', ...purchaseEventData })
  }

  // prettier-ignore
  const viewContentEvent = ( pageName: string, category: ContentCategory, ids: string[]) => {
    logEvent(firebaseAnalytics, 'page_view', { page_title: pageName })
    logEvent(firebaseAnalytics, 'select_content', { content_type: category })
    setCurrentScreen(firebaseAnalytics, pageName)
    // trackSingleEvent('ViewContent', {
    //   content_category: category,
    //   content_name: pageName,
    //   content_ids: ids,
    // })
  }

  const beginCheckoutEvent = (data: {
    price: number
    name: string
    category: ContentCategory
    id: string
  }) => {
    logEvent(firebaseAnalytics, 'begin_checkout', {
      currency: 'USD',
      value: data.price,
    })
    // trackSingleEvent('InitiateCheckout', {
    //   content_ids: [data.id],
    //   content_category: data.category,
    //   currency: 'USD',
    //   value: data.price,
    // })
  }

  return { viewContentEvent, purchaseEvent, pageViewEvent, beginCheckoutEvent }
}
