import { PageHeading } from 'app/layout/PageHeading'
import { Image } from 'app/shared/components/Media/Image'
import React from 'react'

const teachers = [
  {
    name: 'Cameron Brouk',
    description:
      "At first glance, Cameron is the type of guy that seems friendly, sensible, and fun; the type of guy who you want to be friends with, but sooner or later you realize something is wrong. Most people don't notice, but self-loathing drives his everyday actions-making him an unbearable burden for those close to him. Three nights out of the week you can find Cameron on the dance floor, and the other nights he usually just sits by himself at Applebee's Neighborhood Bar & Grill. When he is dancing, Cameron feels true joy. When he is at Applebee's, Cameron shouts loud and hurtful things at families and always ends up getting kicked out. More importantly - I guess - is that Cameron loves Lindy Hop. He wants to teach you how to have fun and be hardcore on the dance floor. By the end of Cameron's classes Cameron usually loses his voice, but will help you find yours.",
    image: '',
  },
  {
    name: 'Liz Schwarzkopf',
    description:
      'Liz might be obsessed with dancing. She got hooked on lindy hop in college and was soon after dragged into blues dancing by her friends. The obsession has since spiraled, and now includes Argentine tango, salsa, and various ballroom dances. She loves helping others move confidently and rhythmically and spreading the joy of swing. When not dancing, Liz can be found baking, knitting, playing with her dogs, and falling off of rocks.',
    image: '',
  },
  {
    name: 'Xander Benziger',
    description:
      "In 1969, Xander created a strain of avacados more addictive than heroine.  He shamelessly sold it on the black market, ruining countless lives.  He created an avacado empire, built upon the shattered dreams of those who became avocAddicted.  15 years later, Xander had made 420 billion dollars, but something was missing.  He felt empty.  His favorite billionaire hobby of buying two politicians and making them fight was not giving him the joy it once did.  So he decided to leave his empire and go on a soul searching journey.  For 20 years he traveled nomadically, living off the land.  At the end of his journey, he found the answer.  Balboa.  This dance gave him true joy.  He spent the next 10 years in an isolated cavern practicing.  He wasted no time on the lesser needs such as food, water, or sleep.  He became a Balboa God with his training plan of 100 ollie's, 100 in-and-outs, 100 spins, and a 10 kilometer run.  Now, as a 30 year old man, he has finally emerged to share his knowledge with the world as a teacher at STL Swing Dance.",
    image: '',
  },
]

export const OurTeachersPage = () => {
  return (
    <section className='relative z-0 bg-gray-200'>
      <PageHeading title='Our Teachers' />
      {/* <div></div>

    <div></div>
    <div></div> */}

      <ul className='grid grid-cols-1 gap-6 px-2 my-10 sm:px-8 sm:grid-cols-2'>
        {teachers.map(teacher => (
          <TeacherCard teacher={teacher} />
        ))}
      </ul>
    </section>
  )
}

type Teacher = {
  name: string
  description: string
  image: string
}

const TeacherCard = ({ teacher }: { teacher: Teacher }) => {
  return (
    <section className='my-2 bg-white'>
      {/* Title */}
      <header className='flex justify-center space-x-1 bg-white'>
        <h2 className='p-2 py-3 text-2xl font-bold'>{teacher.name}</h2>
      </header>

      {/* Image */}
      <Image
        alt='picture of dancing'
        src='images/lhsl-home-cover.jpg'
        className='h-40 sm:h-80'
        fit='cover'
      />

      {/* Description */}
      <div className='shadow sm:rounded-lg'>
        <h3 className='py-2 pl-2 font-light'>Description</h3>
        <div className='px-4 py-5 bg-whit'>
          <dl className='flex flex-col justify-between'>
            {/* Price */}
            {teacher.description}
          </dl>
        </div>
      </div>
    </section>
  )
}

export default OurTeachersPage
