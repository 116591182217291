import React from 'react'
import { Switch, Route } from 'react-router-dom'
// import { usePermissions } from './firebase'
// import { EventPaymentSuccess } from './stlswing/components/Events/EventPaymentSuccess'
// import { GroupClassPaymentSuccess } from './stlswing/components/GroupClass/GroupClassPaymentSuccess'
import {
  EventsPage,
  GroupClassesPage,
  AboutPage,
  PrivateLessonsPage,
  OurTeachersPage,
  ClassResourcesPage,
  // GroupClassPage,
  FaqPage,
  // EventPage,
  // AdminPage,
  HomePage,
} from './stlswing/pages'
// import { MyTickets } from './stlswing/components/Profile/MyTickets'
import { TermsOfService } from './shared/components/Pages/TermsOfService'
import { PrivacyPolicy } from './shared/components/Pages/PrivacePolicy'
// import { ProfilePage } from './stlswing/components/Profile/ProfilePage'
// import GiftsPage from './stlswing/pages/GiftsPage'
// import CalendarPage from './stlswing/pages/CalendarPage'
// import { AttendeesPage } from './stlswing/pages/AttendeesPage'
// import { UpcomingClassesPage } from './stlswing/pages/UpcomingClassesPage'
// import { pipe } from 'ramda'
// import { TicketRedemptionPage } from './stlswing/components/Tickets/TicketRedemptionPage'
// import { ViewTicket } from './stlswing/components/Tickets/ViewTicket'
// import { CardReader } from './stlswing/pages/CardReader'

export type RouteNames =
  | '/swing-dance-events'
  | '/swing-dance-classes'
  | '/swing-dance-classes/groupClasses'
  | '/swing-dance-classes/privateLessons'
  | '/calendar'
  | '/faq'
  | '/admin'
  | '/resources'
  | '/our-teachers'
  | '/about'
  | '/allClasses'
  | '/home'
  | '/users'
  | '/'

const PageGuard = ({ children, canView }: C<{ canView: boolean }>) =>
  canView ? children : children
// <div className='flex justify-center w-screen h-screen pt-20 bg-gray-800'>
//   <p className='text-5xl font-bold text-white'>
//     You Do Not Have Permission To View This Page
//   </p>
// </div>

export const Routes = () => {
  // const { isLoggedIn, hasAnyRole } = usePermissions()

  return (
    <>
      <div>
        {/* {isLoggedIn() && !hasAnyRole(['admin', 'employee']) && <MyTickets />} */}

        <Switch>
          {/* Group Classes */}
          {/* <Route path='/card-reader'>
            <CardReader />
          </Route> */}

          <Route path='/swing-dance-classes/groupClasses/beginner'>
            <GroupClassesPage searchString='Beginner' />
          </Route>

          <Route path='/swing-dance-classes/groupClasses/intermediate'>
            <GroupClassesPage searchString='Intermediate' />
          </Route>

          <Route path='/swing-dance-classes/groupClasses/advanced'>
            <GroupClassesPage searchString='Advanced' />
          </Route>

          {/* <Route path='/calendar'>
            <CalendarPage />
          </Route> */}

          {/* <Route path='/gifts'>
            <GiftsPage />
          </Route> */}

          {/* <Route path='/swing-dance-classes/groupClasses/success/:id'>
            <GroupClassPaymentSuccess />
          </Route> */}
          {/* <Route path='/swing-dance-classes/groupClasses/:id'>
            <GroupClassPage />
          </Route> */}

          <Route path='/swing-dance-classes/groupClasses'>
            <GroupClassesPage />
          </Route>

          {/* Private Lessons */}
          <Route path='/swing-dance-classes/privateLessons'>
            <PrivateLessonsPage />
          </Route>

          {/* <Route path='/swing-dance-classes/upcoming'>
            <UpcomingClassesPage />
          </Route> */}
          {/* Events */}
          {/* <Route path='/swing-dance-events/success/:id'>
            <EventPaymentSuccess />
          </Route> */}
          {/* <Route path='/swing-dance-events/:id'>
            <EventPage />
          </Route> */}

          <Route path='/swing-dance-events'>
            <EventsPage />
          </Route>

          {/* More */}
          <Route path='/resources'>
            <ClassResourcesPage />
          </Route>

          <Route path='/faq'>
            <FaqPage />
          </Route>

          <Route path='/our-teachers'>
            <OurTeachersPage />
          </Route>

          <Route path='/about'>
            <AboutPage />
          </Route>

          <Route path='/terms-of-service'>
            <TermsOfService />
          </Route>
          <Route path='/privacy-policy'>
            <PrivacyPolicy />
          </Route>
          {/* Base */}
          <Route path='/home'>
            <HomePage />
          </Route>
          <Route path='/'>
            <HomePage />
          </Route>

          {/* <Route exact path='/tickets/:orderId/redeem'>
            <TicketRedemptionPage />
          </Route> */}
          {/* <Route exact path='/tickets/:orderId'>
            <ViewTicket />
          </Route> */}
          {/* Admin Routes */}
          {/* <Route path='/admin/Assets'>
            <AdminPage tab='Assets' />
          </Route> */}
          {/* <Route path='/admin/Users'>
            <AdminPage tab='Users' />
          </Route>

          <Route path='/admin/Changelog'>
            <AdminPage tab='Changelog' />
          </Route>

          <Route path='/admin/Costs'>
            <AdminPage tab='Costs' />
          </Route>

          <Route path='/admin/PrivateLessons'>
            <AdminPage tab='PrivateLessons' />
          </Route>

          <Route path='/admin/Teachers'>
            <AdminPage tab='Teachers' />
          </Route>

          <Route path='/admin/Assets'>
            <AdminPage tab='Assets' />
          </Route>

          <Route path='/admin/attendees/:productType/:productId'>
            <AttendeesPage />
          </Route>

          <Route path='/admin/History'>
            <AdminPage tab='History' />
          </Route>

          <Route path='/admin/Orders'>
            <AdminPage tab='Orders' />
          </Route>

          <Route path='/admin/Events'>
            <AdminPage tab='Events' />
          </Route>

          <Route path='/admin/Faq'>
            <AdminPage tab='Faq' />
          </Route>

          <Route path='/admin/Classes'>
            <AdminPage tab='Classes' />
          </Route>

 */}
          {/* <Route path='/admin'>
            <AdminPage />
          </Route> */}
          {/* Terms of Service / Privacy Policy */}
        </Switch>
      </div>
    </>
  )
}
