import React from 'react'

export const TermsOfService = () => (
  <p className='flex justify-center h-screen pt-40 text-white bg-gray-800'>
    I choose to participate in classes, performances, workshops, and other
    activities at STL Swing, LLC of my own free will and certify that I am in
    proper physical condition to take part in such activities. I have not been
    advised to not participate by a qualified medical professional. I HEREBY
    ASSUME ALL OF THE RISKS OF PARTICIPATING AND/OR VOLUNTEERING IN THESE
    ACTIVITIES OR EVENTS, including by way of example and not limitation, any
    risks that may arise from negligence or carelessness on the part of the
    persons or entities being released, from dangerous or defective equipment or
    property owned, maintained, or controlled by them, or because of their
    possible liability without fault. I acknowledge that this Release of
    Liability and Photography Release Waiver will be used by Convergence Dance
    and Body Center, LLC, and that it will govern my actions and
    responsibilities at said activities or events. In consideration of my
    participation, I hereby take the following action for (i) myself, my
    executors, administrators, heirs, next of kin, successors, and assigns; and
    (ii) each of the minor children listed below: (A) I WAIVE, RELEASE, AND
    DISCHARGE from any and all liability, including but not limited to,
    liability arising from the negligence or fault of the entities or persons
    released, for my death, disability, personal injury, property damage,
    property theft, or actions of any kind which may hereafter occur to me
    including my traveling to and from these activities or events, THE FOLLOWING
    ENTITIES OR PERSONS: Convergence Dance and Body Center, LLC, their members,
    owners, employees, staff, instructors, independent contractors, volunteers,
    students, representatives, and agents; and (B) I INDEMNIFY, HOLD HARMLESS,
    AND PROMISE NOT TO SUE the entities or persons mentioned in this paragraph
    from any and all liabilities or claims made as a result of participation in
    these activities or events, whether caused by the negligence of release or
    otherwise. This accident waiver and release of liability shall be construed
    broadly to provide a release and waiver to the maximum extent permissible
    under applicable law. I understand that there are risks of physical injury
    associated with, arising out of, and inherent to dancing. I hereby consent
    to receive medical treatment which may be deemed advisable in the event of
    injury, accident, and/or illness during these activities or events. I
    understand that at these events or related activities, I may be
    photographed. I agree to allow my photo, video, or film likeness to be used
    for any legitimate purpose by Convergence Dance and Body Center, LLC and
    waive any right to royalties or other compensation arising or related to the
    use of my image or recording. I CERTIFY THAT I HAVE READ THIS DOCUMENT, AND
    I FULLY UNDERSTAND ITS CONTENT. I AM AWARE THAT THIS IS A RELEASE OF
    LIABILITY AND A CONTRACT AND I AGREE TO IT OF MY OWN FREE WILL. PARENT /
    GUARDIANS AGREE TO SIGN WAIVER FOR MINORS ON THE DAY OF THE FIRST LESSON
    (Under 18 years old) AT THE STUDIO.
  </p>
)
