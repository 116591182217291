import React, { useEffect } from 'react'
import { PageHeading } from 'app/layout/PageHeading'
import { useAnalytics } from 'app/shared/hooks/useAnalytics'
import { WhatIsSwingDancingPanel } from '../components/Home/WhatIsSwingDancingPanel'

const exampleVideos = [
  {
    name: 'Medium Tempo Lindy Hop',
    description:
      "Lindy Hop is the primary form of dance that we teach at STL Swing.  It's fast, fun, and high enery.  This is an example done to medium tempo music.",
    link: 'https://www.youtube.com/watch?v=BWt4_KpYLgM',
  },
  {
    name: 'Lindy Hop Battle Competition',
    description:
      'Part of Lindy Hop culture is competition.  One of the primary forms of Lindy Hop comps come in the form of battles, where judges watch multiple dancers at the same time.',
    link: 'https://www.youtube.com/watch?v=pGtjSt7u_20',
  },
  {
    name: 'Fast Lindy Hop',
    description:
      "Lindy Hop is the primary form of dance that we teach at STL Swing.  It's fast, fun, and high enery",
    link: 'https://www.youtube.com/watch?v=v9xxeWRxSbA',
  },
  {
    name: 'Slow Swing',
    description: 'A form of swing dance done to slower music',
    link: 'https://www.youtube.com/watch?v=xYtf5Tfdi_w',
  },
  {
    name: 'Solo Jazz',
    description: 'A form of Swing that is danced solo',
    link: 'https://www.youtube.com/watch?v=D1gQ_gjNUtk&list=PL9NYzfVwgE2edYYV3LouvNUqhY12cz9Uh&index=66',
  },
  {
    name: 'Balboa',
    description: 'A form of dance that is done in close embrace to fast music',
    link: 'https://www.youtube.com/watch?v=hU4gsbyYJLY',
  },
]

export const WhatItLooksLikePanel = ({ className }: C) => (
  <div className={`sm:px-8 px-2 py-16 bg-gray-200 ${className}`}>
    <h2 className='text-3xl font-extrabold text-center'>
      What Does It Look Like?
    </h2>

    <ul className='sm:grid-cols-2 lg:grid-cols-2 grid grid-cols-1 gap-6 my-10 sm:max-w-5xl mx-auto'>
      {exampleVideos.map((video, i) => (
        <li className='flex flex-col justify-between col-span-1 bg-white divide-y divide-gray-200 rounded-lg shadow'>
          <div className='flex items-center justify-between w-full p-6 space-x-6'>
            <div className='flex-1'>
              <div className='flex items-center space-x-3'>
                <h3 className='text-sm font-medium text-gray-900 truncate'>
                  {video.name}
                </h3>
              </div>
              <p className='mt-1 text-sm text-gray-500'>{video.description}</p>
            </div>
          </div>
          <a
            className='hover:bg-gray-100 flex justify-center py-4 transition-all duration-300 bg-orange-200'
            href={video.link}
            rel='noreferrer'
            target='_blank'>
            <span className='font-medium'>Watch Video</span>
          </a>
        </li>
      ))}
    </ul>
  </div>
)

export const AboutPage = () => {
  const { pageViewEvent } = useAnalytics()

  useEffect(() => {
    pageViewEvent('About')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className='relative z-0 min-h-screen bg-white'>
      <PageHeading title='About' image='images/what-is-lindyhop.jpg' />

      <WhatIsSwingDancingPanel />

      <WhatItLooksLikePanel />

      {/* <div className='content-center w-full mx-auto'>
        <Tabs tabs={tabs} setTabs={setTabs} />
      </div> */}

      {/* Tabs */}
    </section>
  )
}

export default AboutPage
