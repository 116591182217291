import React, { createContext, useState, useEffect } from 'react'
import { Permissions, User } from './user.types'

import {
  getAuth,
  getRedirectResult,
  onAuthStateChanged,
  signOut,
} from 'firebase/auth'
import { firebaseApp } from '../../../environments'
import {
  doc,
  getFirestore,
  getDoc,
  query,
  collection,
  onSnapshot,
  where,
} from 'firebase/firestore'
import { createDefaultUser, getUserRedirectData } from 'app/auth/GoogleOauth'
import { toast } from 'react-toastify'
import { CollectionNames } from '../firestore'

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

type TCurrentUserContext = {
  user?: User<true>
  permissions: Permissions
  setPermissions: SetState<Permissions>
  setUser: SetState<User<true> | undefined>
  logout: () => void
  userLoading: boolean
}

const defaultPermissions: Permissions = {
  clearance: 0,
  role: 'visitor',
  groups: [],
}

const defaultContext: TCurrentUserContext = {
  permissions: defaultPermissions,
  setPermissions: () => {},
  setUser: () => {},
  logout: () => {},
  userLoading: false,
}

export const CurrentUserContext =
  createContext<TCurrentUserContext>(defaultContext)

export const CurrentUserProvider: FC<{}> = ({ children }) => {
  const [uid, setUid] = useState<string>()
  const [user, setUser] = useState<User<true>>()
  const [permissions, setPermissions] = useState(defaultPermissions)
  const [userLoading, setUserLoading] = useState(true)

  // useEffect(() => {
  //   if (!uid) return
  //   setUserLoading(true)
  //   const firestore = getFirestore(firebaseApp)
  //   async function userDocumentListener<T>(
  //     collectionName: CollectionNames,
  //     onDocFetch: (doc: T) => void,
  //   ) {
  //     const queryRef = query(
  //       collection(firestore, collectionName),
  //       where('id', '==', uid),
  //     )

  //     return await onSnapshot(queryRef, (snapshot) => {
  //       const data = snapshot.docs.map((d) => d.data())
  //       const firstItem = data[0]
  //       console.log('collectionItem', firstItem)
  //       if (!firstItem) return
  //       onDocFetch(firstItem as T)
  //     })
  //   }

  //   userDocumentListener<User<true>>('users', setUser)
  //   userDocumentListener<Permissions>('permissions', setPermissions)
  // }, [uid])

  // useEffect(() => {
  //   if (user && permissions) setUserLoading(false)
  // }, [user, permissions])

  // useEffect(() => {
  //   const auth = getAuth(firebaseApp)
  //   setUserLoading(false)
  //   getUserRedirectData(auth).then((defaultData) => {
  //     if (!defaultData) return
  //     toast.success('Retrieved User Data')
  //     setUser((user) => {
  //       if (user)
  //         return {
  //           ...defaultData.defaultUser,
  //           ...user,
  //         }
  //       return defaultData.defaultUser
  //     })
  //     setPermissions(defaultData.defaultPermissions)
  //   })
  // }, [])

  // Persistent Login using 'onAuthStateChanged' function
  useEffect(() => {
    const auth = getAuth(firebaseApp)
    const firestore = getFirestore(firebaseApp)

    onAuthStateChanged(auth, async (firebaseUser) => {
      setUserLoading(true)
      if (!firebaseUser) return setUserLoading(false)
      setUid(firebaseUser.uid)

      const getUserDoc = () => doc(firestore, 'users/' + firebaseUser.uid)
      const getPermissionsDoc = () =>
        doc(firestore, 'permissions/' + firebaseUser.uid)

      const currentUserDocument = getUserDoc()
      const currentUserPermissionsDocument = getPermissionsDoc()
      if (!currentUserDocument || !currentUserPermissionsDocument) {
        await sleep(2000)
        setUserLoading(false)
        return
      }

      const currentUser = await getDoc(currentUserDocument).then((d) =>
        d.data(),
      )
      const currentUserPermissions = await getDoc(
        currentUserPermissionsDocument,
      ).then((d) => d.data())

      if (!currentUser || !currentUserPermissions) {
        toast.info('One Moment!  We are creating your account')
        await sleep(5000) // Wait for slow backend to finish creating user/permissions

        const newUser = await getDoc(getUserDoc()).then((d) => d.data())
        const newUserPermissions = await getDoc(getPermissionsDoc()).then((d) =>
          d.data(),
        )

        if (newUser && newUserPermissions) {
          setUser(newUser as User<true>)
          setPermissions(newUserPermissions as Permissions)
        } else {
          toast.warn('There was an issue signing you in.  Please try again!')
        }
      }

      if (currentUser && currentUserPermissions) {
        setUser(currentUser as User<true>)
        setPermissions(currentUserPermissions as Permissions)
      }

      setUserLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logout = () => {
    setUser(undefined)
    setPermissions(defaultPermissions)
    signOut(getAuth(firebaseApp))
  }

  return (
    <CurrentUserContext.Provider
      value={{
        user,
        permissions,
        setPermissions,
        setUser,
        logout,
        userLoading,
      }}>
      {children}
    </CurrentUserContext.Provider>
  )
}
