import { useHistory, useLocation, useParams } from 'react-router'
import { useRouteMatch } from 'react-router-dom'
import queryString from 'query-string'

/**
 * This hook exposes objects/functions that are relevant to the current route
 * @description This Hook is just an abstraction of the hooks given by react-router.
 * @returns routeParams - current routes parameters
 * @returns navigateTo - function that navivigates you to the next route
 * @returns currentRoute - the current route
 */
export const useRouter = <Params = {}>() => {
  const params = useParams<Params>()
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()

  const currentRoute = location.pathname

  /**
   * Appends to the route and adds it to the history object
   */
  const navigateTo = history.push

  /**
   * Changes the Entire route
   */
  const redirectTo = history.replace

  return {
    routeParams: {
      ...params,
      ...queryString.parse(location.search),
    },
    match,
    location,
    currentRoute,
    history,
    navigateTo,
    redirectTo,
  }
}
