import React from 'react'

export const CloseIcon = ({ className }: C<{}>) => (
  <svg
    className={`rounded w-7 h-7 text-red-800 hover:bg-gray-200 ${className}`}
    fill='none'
    viewBox='0 0 24 24'
    stroke='currentColor'
    aria-hidden='true'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M6 18L18 6M6 6l12 12'
    />
  </svg>
)
