import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { MobileHeader, DesktopHeader, Footer } from './layout'

import { CurrentUserProvider, useUI } from './shared/hooks/'
import { FirestoreProvider } from './firebase/firestore/FirestoreContext'
import { Routes } from './Routes'

// We need To add the toastify css because purgecss removes it otherwise? I think?  we definitely need it though.
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Carousel CSS
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

// Filepond CSS
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css'
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css'

import { UIProvider } from './shared/context/UIContext'

const reactQueryClient = new QueryClient()

const App = () => {
  const { isMobile } = useUI()
  return (
    <BrowserRouter>
      {/* <CurrentUserProvider> */}
      <UIProvider>
        <QueryClientProvider client={reactQueryClient}>
          <FirestoreProvider>
            <ToastContainer />
            <div className='flex flex-col overflow-hidden'>
              {isMobile ? <MobileHeader /> : <DesktopHeader />}

              <Routes />

              <Footer />
            </div>
          </FirestoreProvider>
        </QueryClientProvider>
      </UIProvider>
      {/* </CurrentUserProvider> */}
    </BrowserRouter>
  )
}

export default App
