import {
  format,
  isFuture,
  startOfMonth,
  endOfMonth,
  eachWeekOfInterval,
  differenceInCalendarMonths,
  isWithinInterval,
  isPast,
  addWeeks,
  addDays,
  isAfter,
} from 'date-fns'
import { uniq, any, tail } from 'ramda'
import { CLASS_TIMES } from '../stlswing.constants'
import { GroupClass } from '../stlswing.types'

// prettier-ignore
export const monthsOfYear = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', ]
// prettier-ignore
export const daysOfWeek = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', ]

export const getAbbreviatedMonthsOfClass = (groupClass: GroupClass) =>
  uniq(groupClass.dates.map((d) => formatDate('abbreviated-month', d)))
export const getMonthsOfClass = (groupClass: GroupClass) =>
  uniq(groupClass.dates.map((d) => formatDate('month', d)))

// prettier-ignore
type dateFormats = 'month' | 'abbreviated-month' | 'day' | 'readable' | 'abbreviated-day' | 'day-first-letter' | 'day-of-year' | 'day-of-month' | 'week-of-year' | 'readable-date-and-time' | 'time' | 'form' | 'seconds' | 'condensed-readable'
export const formatDate = (strFormat: dateFormats, date: string | Date) => {
  if (strFormat === 'seconds') return format(new Date(date), 'T')
  if (strFormat === 'month') return format(new Date(date), 'LLLL')
  if (strFormat === 'abbreviated-month') return format(new Date(date), 'LLL')
  if (strFormat === 'day') return format(new Date(date), 'EEEE')
  if (strFormat === 'abbreviated-day') return format(new Date(date), 'E')
  if (strFormat === 'day-first-letter') return format(new Date(date), 'EEEEE')
  if (strFormat === 'day-of-year') return format(new Date(date), 'D')
  if (strFormat === 'day-of-month') return format(new Date(date), 'd')
  if (strFormat === 'week-of-year') return format(new Date(date), 'l')
  if (strFormat === 'form') return format(new Date(date), 'yyyy-MM-dd')
  if (strFormat === 'time') return format(new Date(date), 'p')
  if (strFormat === 'condensed-readable')
    return `${format(new Date(date), 'E')}, ${format(
      new Date(date),
      'LLL',
    )} ${format(new Date(date), 'd')}`
  if (strFormat === 'readable-date-and-time')
    return (
      format(new Date(date), 'PPPPpppp').slice(0, -16).replace(', 2021', '') +
      'pm'
    )
  return format(new Date(date), 'PPPP').slice(0, -6)
}

export const hasBegun = (dates: string[]) => isPast(new Date(dates[0]))

export const hasFinished = (dates: string[]) =>
  isFuture(new Date(dates[dates.length]))

export const classIsCurrent = (groupClass: GroupClass) =>
  hasBegun(groupClass.dates) && !hasFinished(groupClass.dates)

export const classInMonth = ({ dates }: GroupClass, month: string) =>
  any((d) => month === formatDate('month', d), dates)

export const dateInMonth = (date: string) => {
  const start = startOfMonth(new Date())
  const end = endOfMonth(new Date())
  return isWithinInterval(new Date(date), { start, end })
}

export const anyDateInMonth = any(dateInMonth)

export const monthsUntilClass = ({ dates }: GroupClass) =>
  differenceInCalendarMonths(new Date(dates[0]), new Date())

export const getClassDates = (startDate: Date, classAmount: number) =>
  tail(
    eachWeekOfInterval({
      start: startDate,
      end: addWeeks(startDate, classAmount + 1),
    }).map((w) => getCorrectDayFromWeek(w, format(startDate, 'EEE'))),
  )

export const getWeeksOfMonth = (date: Date) =>
  eachWeekOfInterval({ start: startOfMonth(date), end: endOfMonth(date) })

export const getClassStartTime = ({ dates }: GroupClass) => dates[0].slice(8)

export const getTimeslotFromStarttime = (startTime: string) =>
  Object.entries(CLASS_TIMES).reduce(
    (timeSlot, [key, value]) => (startTime.includes(value) ? key : timeSlot),
    '',
  )

export const getClassDateFromTimeSlot = (
  dates: string[],
  timeSlot: keyof typeof CLASS_TIMES,
) => dates.map((date) => date + CLASS_TIMES[timeSlot])

export const getCorrectDayFromWeek = (
  startOfWeek: Date,
  dayOfWeekToGet: string,
) => {
  // follow the getISODay format (7 for Sunday, 1 for Monday)
  const dayOfWeekMap: Record<string, number> = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thur: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  }
  return addDays(startOfWeek, dayOfWeekMap[dayOfWeekToGet] + 1)
}

export const remainingClassDates = ({ dates }: GroupClass) =>
  dates.filter((date) => isAfter(new Date(date), new Date()))
