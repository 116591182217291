import React, { useState } from 'react'
import * as firebase from 'firebase/firestore'
import { History, useFirestore } from 'app/firebase'
import { prop, toPairs, keys, equals, sortBy, head } from 'ramda'
import { Button, DataTable, Modal, TableSchema, Tabs } from 'app/shared'
import { formatDate } from 'app/stlswing/helpers/date.helpers'
import { UserAvatarHeading } from 'app/users/components/UserAvatarHeading'
import { InfoSection } from '../../Shared/InfoSection'
import { useRevert } from 'app/shared/hooks/useRevert'
import { useQuery } from 'react-query'
import { firebaseApp } from 'environments'
import { subDays, subWeeks } from 'date-fns'
import { AdminMobileHeader } from 'app/stlswing/pages'

const getHistory = async () => {
  const historyQuery = firebase.query(
    firebase.collection(firebase.getFirestore(firebaseApp), 'history'),
    firebase.where('createdAt', '>', subWeeks(new Date(), 1).toISOString()),
  )
  const { docs } = await firebase.getDocs(historyQuery)
  return docs.map((doc) => doc.data() as History)
}

export const DatabaseHistory = () => {
  const { collectionMap: usersMap } = useFirestore('users')

  const { data: history } = useQuery('history', () => getHistory())

  // const { list: history } = useFirestore('history')
  const revert = useRevert()
  const [selectedObject, setSelectedObject] = useState<History>()

  // prettier-ignore
  type Columns = 'Operation' | 'Collection' | 'Date' | 'Origin User' | 'View' | 'Revert'
  const historyTableSchema: TableSchema<History, Columns> = {
    Operation: prop('operation'),
    Collection: prop('collection'),
    Date: (history) =>
      formatDate('readable-date-and-time', new Date(history.createdAt)),
    'Origin User': (history) => (
      <UserAvatarHeading user={usersMap && usersMap[history.createdBy]} />
    ),
    View: (history) => (
      <Button variant='raised' onClick={() => setSelectedObject(history)}>
        View Properties
      </Button>
    ),
    Revert: (history) =>
      history.operation !== 'ERROR' && (
        <Button variant='warn' onClick={() => revert(history)}>
          Revert
        </Button>
      ),
  }

  const getOldBackgroundColor = (
    newData: object,
    previousData: object,
    key: keyof object,
  ) => {
    if (!previousData) return 'bg-white'
    if (!newData) return 'bg-red-100'
    if (equals(previousData[key], newData[key])) return 'bg-white'
    if (!previousData[key] && !!newData[key]) return 'bg-green-100'
    if (!!previousData[key] && !!newData[key]) return 'bg-blue-100'
    return 'bg-red-100'
  }
  const getNewBackgroundColor = (
    newData: object,
    previousData: object,
    key: keyof object,
  ) => {
    if (!previousData) return 'bg-green-100'
    if (!newData || equals(previousData[key], newData[key])) return 'bg-white'
    if (!previousData[key] && !!newData[key]) return 'bg-green-100'
    if (!!previousData[key] && !!newData[key]) return 'bg-blue-100'
  }

  const isError = (history?: History) => history?.operation === 'ERROR'

  return (
    <div className=' w-full space-y-6'>
      <AdminMobileHeader title='History' />

      <DataTable<History>
        tableSchema={historyTableSchema}
        recordsList={history}
        defaultSort={'createdAt'}
      />

      <Modal
        title={`${selectedObject?.operation} ${selectedObject?.collection} document`}
        className='w-screen overflow-y-auto sm:w-1/2'
        type='right-panel'
        isOpen={!!selectedObject}
        onClose={() => setSelectedObject(undefined)}>
        {/* <Tabs<typeof tabs> tabs={tabs} setTabs={setTabs} /> */}
        <div className='flex divide-x'>
          {selectedObject && !isError(selectedObject) && (
            <div className='h-full w-1/2'>
              <div className='divide-y h-full'>
                <h2 className='font-semibold text-lg text-center p-2'>
                  Previous State
                </h2>
                {selectedObject.operation !== 'CREATE' &&
                  keys({
                    ...selectedObject?.metadata,
                    ...selectedObject?.previousState,
                  } as object)
                    .filter((key) => {
                      if (key === 'id') return false
                      if (key === 'updatedAt') return false
                      if (key === 'createdAt') return false
                      return true
                    })
                    .map((key) => (
                      <InfoSection
                        title={key}
                        bgClass={getOldBackgroundColor(
                          selectedObject?.metadata || {},
                          selectedObject.previousState || {},
                          key,
                        )}>
                        {/* @ts-ignore */}
                        {selectedObject?.previousState[key]}
                      </InfoSection>
                    ))}
              </div>
            </div>
          )}

          {!isError(selectedObject) && (
            <div className='h-full w-1/2 divide-y'>
              <h2 className='font-semibold text-lg text-center p-2'>
                New State
              </h2>
              <div className='divide-y w-full'>
                {selectedObject &&
                  toPairs(selectedObject?.metadata as any)
                    .filter(([key, value]) => {
                      if (key === 'id') return false
                      if (key === 'updatedAt') return false
                      if (key === 'createdAt') return false
                      return true
                    })
                    .map(
                      // @ts-ignore
                      ([key, value]: [any, any]) => (
                        <InfoSection
                          title={key}
                          bgClass={getNewBackgroundColor(
                            selectedObject?.metadata || {},
                            selectedObject.previousState || {},
                            // @ts-ignore
                            key,
                          )}>
                          {value.toString()}
                        </InfoSection>
                      ),
                    )}
              </div>
            </div>
          )}

          {isError(selectedObject) && (
            <div className='h-full w-full divide-y'>
              <div className='divide-y w-full'>
                {selectedObject &&
                  toPairs(selectedObject?.metadata as any).map(
                    // @ts-ignore
                    ([key, value]: [any, any]) => (
                      <InfoSection title={key}>{value.toString()}</InfoSection>
                    ),
                  )}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}
