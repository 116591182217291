import React from 'react'
import { Menu } from 'app/shared/components'
import { ArrowDownIcon } from 'app/shared/components/Icons/ArrowDownIcon'

type Props = {
  text: string
  toggle: () => void
  close: () => void
  isOpen: boolean
} & ButtonElement

export const HeaderDropDown = (props: C<Props>) => {
  return (
    <div className='flex justify-center'>
      <button
        onClick={props.toggle}
        className={`px-3 py-2 font-medium items-center jutify-center flex text-white cursor-pointer transition-all duration-500 border-b-2 border-transparent hover:border-white ${props.className}`}>
        {props.text}

        <ArrowDownIcon className='group-hover:text-gray-500 w-5 h-5 mt-1 text-white' />
      </button>
      <Menu className='mt-12' isVisible={props.isOpen} onClose={props.close}>
        {props.children}
      </Menu>
    </div>
  )
}
