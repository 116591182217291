import React, { useEffect, useState } from 'react'
import { Image } from 'app/shared/components/Media/Image'
import { Video } from 'app/shared/components/Media/Video'

type Props = {
  title: string
  description?: string
  url?: string
  image?: string
  video?: string
  fullPage?: boolean
}

type ContentProps = {
  title: string
}
const PageContent = ({ title, children }: C<ContentProps>) => (
  <div className='flex flex-col items-center justify-center w-full h-full bg-transparent'>
    <h1 className='p-5 text-4xl font-semibold text-white bg-orange-800 bg-opacity-50 rounded-lg backdrop-filter backdrop-blur-lg'>
      {title}
    </h1>
    <div>{children}</div>
  </div>
)

export const PageHeading = ({ title, image, video, ...props }: C<Props>) => {
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  return (
    <div className='relative'>
      {video && (
        <Video
          alt='video of people dancing'
          fit='cover'
          src={video ? video : 'videos/battle-allskate.mp4'}
          overlay
          className={`w-full ${props.fullPage ? 'h-screen' : 'h-96'}`}>
          <PageContent title={title}>{props.children}</PageContent>
        </Video>
      )}
      {!video && (
        <Image
          src={image ? image : 'images/lhsl-home-cover.jpg'}
          alt='photo of people swing dancing'
          fit='cover'
          overlay
          className={`w-full ${props.fullPage ? 'h-screen' : 'h-96'}`}>
          <PageContent title={title}>{props.children}</PageContent>
        </Image>
      )}
    </div>
  )
}
