import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import devEnvironment from './environment.dev'
import prodEnvironment from './environment.prod'

// @ts-ignore
// export const ENV: 'DEV' | 'PROD' = process.env.REACT_APP_ENV
export const ENV: 'DEV' | 'PROD' = 'PROD'

// const environment =
//   process.env.REACT_APP_ENV === 'PROD' ? prodEnvironment : devEnvironment
// @ts-ignore
const environment = ENV === 'PROD' ? prodEnvironment : devEnvironment

export const firebaseApp = initializeApp(environment.firebaseConfig)

export default environment
