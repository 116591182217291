export const devEnvironment = {
  production: false,
  firebaseConfig: {
  apiKey: "AIzaSyBxz3kCWoDr0hRoRvrTr9v6jJpq9TKK9PE",
  authDomain: "stlswingdev.firebaseapp.com",
  projectId: "stlswingdev",
  storageBucket: "stlswingdev.appspot.com",
  messagingSenderId: "1035348409584",
  appId: "1:1035348409584:web:6a1fea7ec6c0c22fe49635",
  measurementId: "G-2HSLWSXR2V"
  },
}

export default devEnvironment
