import { useCallback } from 'react'
import { uniq, without } from 'ramda'
import { useFirestore } from '../firestore/useFirestore'
import { Clearance, Role, Group } from './user.types'
import { CurrentUserContext } from './CurrentUserContext'
import { useContext } from 'react'

/**
 * This hook exposes helper functions that allow you to change a users
 * permissions, or check whether a user has a certain permission.
 * @warning This hook can only be used inside of a component wrapped with CurrentUserContext
 */
export const usePermissions = () => {
  const { permissions, user } = useContext(CurrentUserContext)
  const { update: updatePermissions } = useFirestore('permissions')

  const { groups, role, clearance } = permissions

  const addKey = (group: Group) => {
    if (!user?.id) return
    updatePermissions(user.id, { groups: uniq([...groups, group]) })
  }

  const removeKeys = (...group: Group[]) => {
    if (!user?.id) return
    updatePermissions(user.id, { groups: without([...group], groups) })
  }

  const changeClearance = (clearance: Clearance) => {
    if (!user?.id) return
    updatePermissions(user.id, { clearance })
  }

  const changeRole = (role: Role) => {
    if (!user?.id) return
    updatePermissions(user.id, { role })
  }

  const hasRole = (requiredRole: Role) => role === requiredRole

  const hasAnyRole = useCallback(
    (roles: Role[]) =>
      roles.reduce((hasPermission, currRole) => {
        if (hasPermission) return true
        return currRole === role
      }, false),
    [role],
  )

  const hasClearance = (requiredClearance: Clearance) =>
    clearance >= requiredClearance

  const hasGroup = (groupRequired: Group) => groups.includes(groupRequired)

  const isLoggedIn = () => permissions.role !== 'visitor' && !!user

  return {
    hasAnyRole,
    addKey,
    removeKeys,
    changeClearance,
    changeRole,
    hasRole,
    hasClearance,
    hasGroup,
    isLoggedIn,
    currentUserPermissions: permissions,
  }
}
