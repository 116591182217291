import React from 'react'
import { useToggle } from 'app/shared/hooks'
import { HeaderDropDown } from '../components/HeaderDropDown'
import { HeaderDropDownLink } from '../components/HeaderDropDownLink'

export const ClassesMenu = () => {
  const { toggle, setFalse: close, state: isOpen } = useToggle()

  return (
    <HeaderDropDown
      isOpen={isOpen}
      toggle={toggle}
      close={close}
      text='Classes'>
      <div className='relative grid items-start gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8'>
        <HeaderDropDownLink
          route='/swing-dance-classes/groupClasses'
          description=' Join a group of students to learn dance from a pair of instructors'
          closeMenu={close}>
          Group Classes
        </HeaderDropDownLink>

        <HeaderDropDownLink
          route='/swing-dance-classes/privateLessons'
          description='Meet an individual instructor for a more personalized, focused lesson.'
          closeMenu={close}>
          Private Lessons
        </HeaderDropDownLink>
      </div>
    </HeaderDropDown>
  )
}
